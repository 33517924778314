import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface CadastrarNewsletter {
  nome: string,
  email: string,
  telefone: string
}


@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(
    private http: HttpClient
  ) { }

  receberNews(parameters: CadastrarNewsletter) {
    return this.http.post(`${environment.url.api}/private/v1/drctNewLetter`, parameters);
  }

}
