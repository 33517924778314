import { Component, OnInit } from '@angular/core';
import { Data, AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';
import { SessionService, SignIn } from 'src/app/services/session/session.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalSignInComponent } from 'src/app/shared/modal-sign-in/modal-sign-in.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {

  public loading: boolean;
  public currencies = ['USD', 'EUR'];
  public currency: any;
  public flags = [
    { name: 'English', image: 'assets/images/flags/gb.svg' },
    { name: 'German', image: 'assets/images/flags/de.svg' },
    { name: 'French', image: 'assets/images/flags/fr.svg' },
    { name: 'Russian', image: 'assets/images/flags/ru.svg' },
    { name: 'Turkish', image: 'assets/images/flags/tr.svg' }
  ]

  public userIcon: string = 'https://minori-casa.s3.amazonaws.com/site-images/icons/account-user.png';

  public user = {
    logged: 'https://minori-casa.s3.amazonaws.com/site-images/icons/account-user-logged.png',
    user: 'https://minori-casa.s3.amazonaws.com/site-images/icons/account-user.png'
  }

  public navigateLogin: boolean = false
  public log;
  public flag: any;
  public userInformation: SignIn

  public catalogos: Array<any> = [
    // { href: 'http://institucional.maioricasa.com.br/assets/pdf/catalogo_decora.pdf', name: 'Decoração', icon: 'library_books' },
    // { href: 'http://institucional.maioricasa.com.br/assets/pdf/catalogo_tapetex.pdf', name: 'Tapetes', icon: 'library_books' },
  ]

  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    private sessionService: SessionService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.sessionService.isLogged$.subscribe(data => {
        this.log = data;
        if (this.log === true) {
          this.userIcon = this.user.logged
        } else {
            this.userIcon = this.user.user
        }
    });

    this.sessionService.information$
      .subscribe((data: SignIn) => {
        this.userInformation = data;
    });

    this.sessionService.emitLogin.subscribe({next: (value: any) => {
        if (value) {
            this.sessionService.information$
            .subscribe((data: SignIn) => {
                this.userInformation = data;
            });
        }
    } })

    this.currency = this.currencies[0];
    this.flag = this.flags[0];
  }

  logout() {
    this.sessionService.logout();
    this.sessionService.isLoggedIn$.next(false);
    this.sessionService.emitLogin.emit(false);
    this.snackBar.open('Disconnected successfully', '', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
  }

  public openDialogSignIn() {
    let log;
    this.sessionService.isLogged$.subscribe(data => {
      log = data;
    });

    if (log === false) {
        this.dialog.open(ModalSignInComponent, {
            data: {},
            height: '600px',
            width: '615px',
            maxWidth: '50vw',
            maxHeight: '90vh',
            panelClass: "dialog-container-arredondado"
          });

        }
  }


  generateUrl(url, params) {
    let i = 0, key;
    for (key in params) {
      if (i === 0) {
        url += '?';
      } else {
        url += '&';
      }
      url += key;
      url += '=';
      url += params[key];
      i++;
    }
    return url;
  }

  configLoja() {
    const codCLiente = this.userInformation.codCliente;
    const token = this.userInformation.token;
    window.location = this.generateUrl('http://configurador-b2c.mustti.com.br', {
      token,
      codCLiente
    });
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }


  public changeLang(flag) {
    this.flag = flag;
  }
}
