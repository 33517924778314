import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface RecuperarSenha {
  mensagem: string
}


@Injectable({
  providedIn: 'root'
})
export class RecuperarSenhaService {

  constructor(private http: HttpClient) { }

  recuperarSenha(values: any): Observable<RecuperarSenha> {

    // there's no CNPJ in the USA so we need to specify an empty value
    values.cnpj = "";

    const params = new HttpParams({ fromObject: values });
    return this.http.get(`${environment.url.api}/public/v1/drctRecuperarSenha?`, { params }) as Observable<RecuperarSenha>;
  }
}
