<!-- <button mat-mini-fab class="swiper-button-prev swipe-arrow swiperPrevBanner">
  <mat-icon>keyboard_arrow_left</mat-icon>
</button> -->

<div class="swiper-container h-100" id="containerBanner" [swiper]="config">
  <div class="swiper-wrapper h-100">
      <div *ngFor="let slide of slides" class="swiper-slide slideBanner">
          <a (click)="goTo(slide.urlDirecionada)" style="cursor: pointer;" *ngIf="slide.urlDirecionada !== ''">
              <img src="{{slide.url}}" alt="{{slide.descricao}}">
          </a>
          <img src="{{slide.url}}" alt="{{slide.descricao}}" *ngIf="slide.urlDirecionada === ''">
      </div>
  </div>
  <div class="swiper-pagination white"></div>
</div>

<!-- <button mat-mini-fab class="swiper-button-next swipe-arrow swiperNextBanner">
<mat-icon>keyboard_arrow_right</mat-icon>
</button> -->