import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { SessionService } from './services/session/session.service';
import { interval } from 'rxjs';
import { version } from '../../package.json';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  loading = false;
  public settings: Settings;
  public isMobile = false;
  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private sessionService: SessionService,
    private renderer: Renderer2
  ) {
    this.settings = this.appSettings.settings;
    this.isMobile = screen.width > 768 ? false : true;
  }

  ngOnInit() {
    // console.log(`Version: ${version}`);
    interval(2000).subscribe((_) => this.sessionService.isActive());
    // if (this.isMobile === true) {
    //   const script = `https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css`;
    //   this.addJsToElement(script, document.head);
    // }
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  addJsToElement(src: string, local: any): HTMLLinkElement {
    const script = document.createElement('link');
    script.type = 'text/css';
    script.href = src;
    script.rel = 'stylesheet';
    this.renderer.appendChild(local, script);
    return script;
  }
}
