<footer *ngIf="!isMobile" class="bg-primary" [ngStyle]="{
    backgroundImage: 'url(https://minori-casa.s3.amazonaws.com/site-images/background-stripes-footer.png)',
    backgroundSize: 'cover'
  }">
    <div class="toggle-btn theme-container" (click)="toggleContent()">
        <i class="fas fa-chevron-up" [ngClass]="{ 'rotate': isOpen }"></i>
    </div>
    <div class="content">
        <div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between" class="theme-container">
                <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="30" class="rodape-sobre">
                    <h3 class="col-title">Minori Casa</h3>
                    <p class="links-sobre"><a href="https://3dwarehouse.sketchup.com/by/minoricasa?tab=collections"
                            target="_blank" class="link primary-color">3D Models</a></p>
                    <p class="links-sobre"><a routerLink="/rug-care" class="link primary-color mt-1">Rug Care</a></p>
                    <p class="links-sobre"><a routerLink="/samples-program" class="link primary-color mt-1">Sample
                            Program</a></p>
                    <p class="links-sobre"><a routerLink="/terms-conditions" class="link primary-color mt-1">Terms &
                            Conditions</a></p>
                </div>
                <div fxFlex="100" fxFlex.gt-sm="40" fxFlex.sm="40" fxFlexAlign="center"
                    class="rodape-social align-center">
                    <div class="row" style="margin-left: 1.5rem;">
                        <p class="links-midia">Follow us</p>
                        <p class="mt-2 primary-color">
                            <span class="d-inline-block mr-1">
                                <a [href]="redesBase?.pinterest" target="_blank" class="primary-color d-flex-center">
                                    <i class="fa fa-pinterest float-icon" style="font-size:35px;color:#6A4E3E"></i>
                                </a>
                            </span>
                            <span class="d-inline-block mr-1">
                                <a [href]="redesBase?.instagram" target="_blank" class="primary-color d-flex-center">
                                    <img src="../../assets/icons/footer/icon-awesome-instagram.png" alt="icon-instagram"
                                        class="icons-p" srcset="">
                                </a>
                            </span>
                            <span class="d-inline-block mr-1">
                                <a [href]="redesBase?.facebook" target="_blank" class="primary-color d-flex-center">
                                    <img src="../../assets/icons/footer/icon-awesome-facebook.png" alt="icon-facebook"
                                        class="icons-p" srcset="">
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
                <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="30" class="rodape-atendimento">
                    <h3 class="col-title" fxLayoutAlign="end center">Help? Contact us!</h3>
                    <div class="row">
                        <p fxLayout="row" fxLayoutAlign="end center" class="links-sobre">
                            <a style="letter-spacing:0.098rem" class="link primary-color"> Office: + 1 786-452-8980</a>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="end center" class="links-sobre">
                            <a style="letter-spacing:0.05rem" class="link primary-color"
                                href="https://api.whatsapp.com/send?phone=+13059273050&text=Hey,%20I%20have%20a%20question%20about"
                                target="_blank"> Sales: + 1 305-927-3050</a>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="end center" class="links-sobre">
                            <span style="letter-spacing: 0.155rem;"><a href="mailto:{{email}}"
                                    class="link primary-color">{{email}}</a></span>
                        </p>
                    </div>
                </div>

            </div>
            <div>
                <div class="theme-container">
                    <hr class="line-footer" />
                </div>
                <div class="theme-container" fxLayout="row wrap" fxLayoutAlign="space-between">
                    <p class="links-sobre">
                        <a class="primary-color">2023 Minori Casa - 1.2.19</a>
                    </p>
                    <p class="links-sobre">
                        <a class="link primary-color" target="_blank"
                            href="https://minori-casa.s3.amazonaws.com/uploads/Privacy-Policy.pdf">
                            Privacy Policy</a>
                    </p>
                </div>
            </div>

        </div>
    </div>
</footer>

<footer *ngIf="isMobile" class="bg-primary" >
    <mat-accordion>
        <div class="toggle-btn" (click)="toggleContent()">
            <i class="fas fa-chevron-up"></i>
        </div>
        <div class="content" fxLayout="column">
            <div fxFlex="100" fxFlexAlign="center" class="rodape-social py-3 align-center">
                <div class="row">
                    <p class="links-midia" style="font-size: 1.5rem;">Follow Us</p>
                    <p class="mt-2 primary-color">
                        <span class="d-inline-block mr-1">
                            <a [href]="redesBase?.pinterest" target="_blank" class="primary-color">
                                <i class="fa fa-pinterest icons-p" style="font-size:47px;color:#6A4E3E; margin-bottom:2px;"></i>
                            </a>
                        </span>
                        <span class="d-inline-block mr-1">
                            <a [href]="redesBase?.instagram" target="_blank" class="primary-color d-flex-center">
                                <img src="../../assets/icons/footer/icon-awesome-instagram.png" alt="icon-instagram"
                                    class="icons-p" srcset="">
                            </a>
                        </span>
                        <span class="d-inline-block mr-1">
                            <a [href]="redesBase?.facebook" target="_blank" class="primary-color d-flex-center">
                                <img src="../../assets/icons/footer/icon-awesome-facebook.png" alt="icon-facebook"
                                    class="icons-p" srcset="">
                            </a>
                        </span>
                    </p>
                </div>
            </div>
            <hr class="line-footer" />
            <div fxFlex="100" class="rodape-sobre py-3">
                <p class="links-sobre"><a href="https://3dwarehouse.sketchup.com/by/minoricasa?tab=collections"
                    target="_blank" class="link primary-color">3D Models</a></p>
                <p (click)="openDialogContact()" class="links-sobre link primary-color">Contact</p>
                <p class="links-sobre"><a routerLink="/samples-program" class="link primary-color mt-1">Samples
                    Program</a></p>
            <p class="links-sobre"><a routerLink="/terms-conditions" class="link primary-color mt-1">Terms &
                    Conditions</a></p>
            </div>
            <hr class="line-footer" />
            <div fxFlex="100" class="rodape-atendimento py-3">
                <h3 class="col-title mt-2" fxLayoutAlign="center center">Help? Contact Us!</h3>
                <p fxLayout="row" fxLayoutAlign="center center" class="mt-1 links-sobre">
                    <a style="letter-spacing:0.098rem" class="link primary-color">Office: + 1 786-452-8980</a>
                </p>
                <p fxLayout="row" fxLayoutAlign="center center" class="mt-1 links-sobre">
                    <a style="letter-spacing:0.05rem" class="link primary-color"
                            href="https://api.whatsapp.com/send?phone=+13059273050&text=Hey,%20I%20have%20a%20question%20about"
                            target="_blank">Sales: + 1 305-927-3050</a>
                </p>
                <p fxLayout="row" fxLayoutAlign="center center" class="mt-1 mb-2 links-sobre">
                    <span style="letter-spacing: 0.155rem;"><a href="mailto:{{email}}"
                        class="link primary-color">{{email}}</a>
                    </span>
                </p>
            </div>
            <hr class="line-footer" />
            <div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                    <div fxFlex="100" class="rodape-sobre py-3">
                        <p class="links-sobre-rodape mt-2">
                            <a class="link primary-color" target="_blank"
                                href="https://minori-casa.s3.amazonaws.com/uploads/Privacy-Policy.pdf">
                                Privacy Policy</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </mat-accordion>

</footer>