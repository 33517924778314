<div class="backLoading backLoading-maioricasa" *ngIf="loading">
    <div class="maskLoad loadingPage text-center loading-maioricasa">
      <ng-lottie
      [options]="options" 
      (animationCreated)="onAnimate($event)">
    </ng-lottie>
    </div>
</div>


<!-- <div class="backLoading backLoading-maioricasa" *ngIf="loading">
  <div class="maskLoad loadingPage text-center loading-maioricasa"></div>
</div> -->