<app-loading [loading]="loading"></app-loading>

<mat-sidenav-container class="fonte-abel">
    <div [hidden]="!isHome">
        <div *ngIf="!isMobile" class="container-main">
            <!-- <section id="home" [ngStyle]="{
                backgroundImage: 'url(https://maioricasa.s3.amazonaws.com/site-images/home/imagem-home.png)'
              }"></section> -->
            <app-video-section></app-video-section>
        </div>
    </div>

    <!-- HOME MOBILE -->

    <!-- MENU SIDENAV MOBILE -->
    <mat-sidenav
        class="menuNaoFlutuanteMobile"
        style="display: fixed; opacity: 1"
        [opened]="false"
        mode="over"
        #sidenav
        class="sidenav mat-elevation-z6"
        [ngStyle]="{
            backgroundImage:
                'url(https://minori-casa.s3.amazonaws.com/site-images/background-stripes-sidenav.png)',
            backgroundSize: 'cover'
        }"
    >
        <div class="cabecalho">
            <img
                id="img-logo-sidenav"
                src="{{ linksLogo.negativo }}"
                alt="Logo"
            />
            <div class="close">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="searchMenu"
                    #searchMenuTrigger="matMenuTrigger"
                    class="search-toggle-btn"
                    (click)="setFocusInput()"
                    class="button-with-image-sidenav"
                >
                    <i
                        style="font-size: 24px"
                        class="material-icons searchSideMenu"
                        >search</i
                    >
                </button>
                <button mat-icon-button (click)="sidenav.close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <app-sidenav-menu
            [menuItems]="sidenavMenuItems"
            [menuParentId]="0"
        ></app-sidenav-menu>
        <div class="text-center py-2"></div>
    </mat-sidenav>
    <!-- FIM MENU SIDENAV MOBILE -->

    <div fxHide="false" fxHide.gt-sm>
        <div *ngIf="isHome; else pages">
            <div
                class="menuNaoFlutuanteMobile"
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                "
            >
                <mat-toolbar
                    class="toolbar-color color-menu p-2;"
                    (mouseover)="changeMenu(true)"
                    (mouseout)="changeMenu(false)"
                >
                    <!-- LOGO -->
                    <mat-toolbar-row
                        fxLayout.sm="row"
                        fxLayoutAlign="space-between center"
                        class="logo-toolbar theme-container"
                    >
                        <div
                            *ngIf="isMobile"
                            (click)="routerTo('/')"
                            class="logo-margin"
                            fxFlex="85"
                        >
                            <a (click)="closeSubMenus()">
                                <img
                                    id="img-logo-sidenav"
                                    src="{{ linksLogo.positivo }}"
                                    alt="Logo"
                                />
                            </a>
                        </div>

                        <div
                            id="menuDropdown"
                            fxFlex="50"
                            fxFlex.gt-md="75"
                            fxFlex.gt-sm="75"
                            fxFlex.gt-xs="75"
                            fxShow="false"
                            fxLayout="row"
                            fxShow.gt-sm
                            class="divMenu"
                        >
                            <div *ngFor="let item of menus">
                                <div
                                    *ngIf="!item.possuiSubMenu"
                                    class="menu-padding"
                                >
                                    <a
                                        [ngClass]="{
                                            'font-color-home':
                                                !item.menuSelecionado,
                                            'font-color-selected':
                                                item.menuSelecionado
                                        }"
                                        class="middle"
                                        (click)="menuRouterTo(item)"
                                        >{{ item?.rotulo }}</a
                                    >
                                </div>

                                <div
                                    *ngIf="item.possuiSubMenu"
                                    class="menu-padding"
                                >
                                    <a
                                        [ngClass]="{
                                            'font-color-home':
                                                !item.menuSelecionado,
                                            'font-color-selected':
                                                item.menuSelecionado
                                        }"
                                        class="middle"
                                        (click)="
                                            item.menuSelecionado =
                                                !item.menuSelecionado
                                        "
                                        [matMenuTriggerFor]="before"
                                    >
                                        <div class="with-icon">
                                            {{ item?.rotulo
                                            }}<i class="material-icons"
                                                >arrow_drop_down</i
                                            >
                                        </div>
                                    </a>
                                    <mat-menu
                                        #before="matMenu"
                                        xPosition="before"
                                        backdropClass="customize"
                                    >
                                        <div *ngFor="let ret of subItems">
                                            <div *ngIf="ret.id === item.id">
                                                <a
                                                    *ngFor="
                                                        let cat of ret?.itens
                                                    "
                                                    class="middle font-color-default"
                                                    mat-menu-item
                                                    (click)="menuRouterTo(cat)"
                                                    >{{ cat.name }}</a
                                                >
                                            </div>
                                        </div>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>

                        <!-- SEARCH BUTTON -->
                        <div
                            fxFlex="12"
                            fxLayout="row"
                            fxLayoutAlign="space-around"
                        >
                            <div fxLayoutAlign="center center">
                                <!-- <button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger"
                                        class="search-toggle-btn" (click)="setFocusInput()">
                                        <mat-icon class="icon">search</mat-icon>                       
                                    </button> -->

                                <button
                                    class="search-botao"
                                    mat-icon-button
                                    [matMenuTriggerFor]="searchMenu"
                                    #searchMenuTrigger="matMenuTrigger"
                                    (click)="setFocusInput()"
                                >
                                    <i
                                        class="material-icons searchSideMenu"
                                        style="color: #fff; font-size: 26px"
                                        >search</i
                                    >
                                </button>

                                <mat-menu
                                    #searchMenu="matMenu"
                                    [overlapTrigger]="false"
                                    xPosition="before"
                                    class="search-dropdown mat-elevation-z8"
                                >
                                    <ng-template matMenuContent>
                                        <!-- (keyup.enter)="searchList(this.pesquisaForm.controls.valorPesquisa.value, true)" -->
                                        <form
                                            [formGroup]="pesquisaForm"
                                            class="search-form"
                                        >
                                            <input
                                                type="text"
                                                formControlName="valorPesquisa"
                                                placeholder="Search"
                                                name="valorPesquisa"
                                                fxFlex
                                                id="inputPesquisa"
                                            />
                                            <button
                                                mat-mini-fab
                                                (click)="
                                                    searchList(
                                                        this.pesquisaForm
                                                            .controls
                                                            .valorPesquisa
                                                            .value,
                                                        true
                                                    )
                                                "
                                                type="button"
                                                class="search-btn mat-elevation-z0 text-muted"
                                            >
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </form>

                                        <div
                                            style="background-color: white"
                                            fxLayout="row"
                                            *ngIf="lista.length > 0"
                                            class="resultadoPesquisa"
                                            id="divBusca"
                                        >
                                            <div class="rolagemPesquisaMobile">
                                                <table fxFlex>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="
                                                                let item of lista
                                                            "
                                                            (click)="
                                                                zerarLista()
                                                            "
                                                        >
                                                            <td
                                                                class="wrap-20"
                                                                (click)="
                                                                    changeRoute(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <a>
                                                                    <img
                                                                        [src]="
                                                                            item
                                                                                ?.imagens
                                                                                .length >
                                                                            0
                                                                                ? item
                                                                                      ?.imagens[0]
                                                                                      .url
                                                                                : 'assets/images/others/error-24px.svg'
                                                                        "
                                                                        alt="item.descricao"
                                                                        width="75"
                                                                    />
                                                                </a>
                                                            </td>

                                                            <td
                                                                class="wrap-80 padding-left"
                                                                (click)="
                                                                    changeRoute(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <a>
                                                                    <div
                                                                        *ngIf="
                                                                            item
                                                                                .descricaoCor
                                                                                .length >
                                                                            0
                                                                        "
                                                                    >
                                                                        {{
                                                                            item?.descricaoGrade
                                                                        }}
                                                                        -
                                                                        {{
                                                                            item?.descricaoCor
                                                                        }}
                                                                    </div>
                                                                    <div
                                                                        *ngIf="
                                                                            item
                                                                                .descricaoCor
                                                                                .length <=
                                                                            0
                                                                        "
                                                                    >
                                                                        {{
                                                                            item?.descricaoGrade
                                                                        }}
                                                                    </div>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-menu>
                            </div>
                        </div>

                        <div
                            fxFlex="12"
                            *ngIf="isLogged"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                        >
                            <div
                                [fxFlex.gt-md]="qtdeTotalItens > 0 ? 30 : 22"
                                [fxFlex.gt-sm]="qtdeTotalItens > 0 ? 30 : 22"
                                [fxFlex.gt-xs]="qtdeTotalItens > 0 ? 30 : 22"
                            >
                                <div fxLayoutAlign="center center">
                                    <a
                                        mat-button
                                        (click)="routerTo('/cart')"
                                        class="flex-row-button"
                                    >
                                        <img
                                            src="https://maioricasa.s3.amazonaws.com/site-images/icons/icon-cart-white.png"
                                            alt="icon-shopping-cart"
                                            class="icons-p"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <span
                            fxHide="false"
                            fxHide.gt-sm
                            fxLayoutAlign="space-around center"
                            class="theme-container"
                        >
                            <button
                                mat-button
                                (click)="sidenav.toggle()"
                                class="sidenav-toggle"
                            >
                                <mat-icon
                                    style="
                                        font-size: 35px;
                                        color: #fff;
                                        margin-bottom: 8%;
                                    "
                                    >menu</mat-icon
                                >
                            </button>
                        </span>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </div>
        <ng-template #pages>
            <div
                class="menuNaoFlutuante"
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                "
            >
                <mat-toolbar
                    class="toolbar-color color-menu p-2;"
                    (mouseover)="changeMenu(true)"
                    (mouseout)="changeMenu(false)"
                >
                    <mat-toolbar-row
                        fxLayout="row"
                        class="logo-toolbar theme-container"
                    >
                        <!-- LOGO -->
                        <div
                            *ngIf="isMobile"
                            (click)="routerTo('/')"
                            class="logo-margin"
                            fxFlex.lt-md="76"
                            fxFlex.xs="65"
                        >
                            <a (click)="closeSubMenus()">
                                <img
                                    id="img-logo-sidenav"
                                    src="{{ linksLogo.negativo }}"
                                    alt="Logo"
                                />
                            </a>
                        </div>

                        <div
                            id="menuDropdown"
                            fxFlex="50"
                            fxFlex.gt-md="75"
                            fxFlex.gt-sm="75"
                            fxFlex.gt-xs="75"
                            fxShow="false"
                            fxLayout="row"
                            fxShow.gt-sm
                            class="divMenu"
                        >
                            <div *ngFor="let item of menus">
                                <div
                                    *ngIf="!item.possuiSubMenu"
                                    class="menu-padding"
                                >
                                    <a
                                        [ngClass]="{
                                            'font-color-home':
                                                !item.menuSelecionado,
                                            'font-color-selected':
                                                item.menuSelecionado
                                        }"
                                        class="middle"
                                        (click)="menuRouterTo(item)"
                                        >{{ item?.rotulo }}</a
                                    >
                                </div>

                                <div
                                    *ngIf="item.possuiSubMenu"
                                    class="menu-padding"
                                >
                                    <a
                                        [ngClass]="{
                                            'font-color-home':
                                                !item.menuSelecionado,
                                            'font-color-selected':
                                                item.menuSelecionado
                                        }"
                                        class="middle"
                                        (click)="
                                            item.menuSelecionado =
                                                !item.menuSelecionado
                                        "
                                        [matMenuTriggerFor]="before"
                                    >
                                        <div class="with-icon">
                                            {{ item?.rotulo
                                            }}<i class="material-icons"
                                                >arrow_drop_down</i
                                            >
                                        </div>
                                    </a>
                                    <mat-menu
                                        #before="matMenu"
                                        xPosition="before"
                                        backdropClass="customize"
                                    >
                                        <div *ngFor="let ret of subItems">
                                            <div *ngIf="ret.id === item.id">
                                                <a
                                                    *ngFor="
                                                        let cat of ret?.itens
                                                    "
                                                    class="middle font-color-default"
                                                    mat-menu-item
                                                    (click)="menuRouterTo(cat)"
                                                    >{{ cat.name }}</a
                                                >
                                            </div>
                                        </div>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>

                        <!-- SEARCH BUTTON -->
                        <div fxFlex="6" fxFlex.xs="13" fxLayout="row">
                            <div fxLayoutAlign="center center">
                                <button
                                    class="search-botao"
                                    mat-icon-button
                                    [matMenuTriggerFor]="searchMenu"
                                    #searchMenuTrigger="matMenuTrigger"
                                    (click)="setFocusInput()"
                                >
                                    <i
                                        style="font-size: 28px; color: #6a4e3e"
                                        class="material-icons searchSideMenu"
                                        >search</i
                                    >
                                </button>

                                <mat-menu
                                    #searchMenu="matMenu"
                                    [overlapTrigger]="false"
                                    xPosition="before"
                                    class="search-dropdown mat-elevation-z8"
                                >
                                    <ng-template matMenuContent>
                                        <!-- (keyup.enter)="searchList(this.pesquisaForm.controls.valorPesquisa.value, true)" -->
                                        <form
                                            [formGroup]="pesquisaForm"
                                            class="search-form"
                                        >
                                            <input
                                                type="text"
                                                formControlName="valorPesquisa"
                                                placeholder="Search"
                                                name="valorPesquisa"
                                                fxFlex
                                                id="inputPesquisa"
                                            />
                                            <button
                                                mat-mini-fab
                                                (click)="
                                                    searchList(
                                                        this.pesquisaForm
                                                            .controls
                                                            .valorPesquisa
                                                            .value,
                                                        true
                                                    )
                                                "
                                                type="button"
                                                class="search-btn mat-elevation-z0 text-muted"
                                            >
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </form>

                                        <div
                                            style="background-color: white"
                                            fxLayout="row"
                                            *ngIf="lista.length > 0"
                                            class="resultadoPesquisa"
                                            id="divBusca"
                                        >
                                            <div class="rolagemPesquisaMobile">
                                                <table fxFlex>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="
                                                                let item of lista
                                                            "
                                                            (click)="
                                                                zerarLista()
                                                            "
                                                        >
                                                            <td
                                                                class="wrap-20"
                                                                (click)="
                                                                    changeRoute(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <a>
                                                                    <img
                                                                        [src]="
                                                                            item
                                                                                ?.imagens
                                                                                .length >
                                                                            0
                                                                                ? item
                                                                                      ?.imagens[0]
                                                                                      .url
                                                                                : 'assets/images/others/error-24px.svg'
                                                                        "
                                                                        alt="item.descricao"
                                                                        width="75"
                                                                    />
                                                                </a>
                                                            </td>

                                                            <td
                                                                class="wrap-80 padding-left"
                                                                (click)="
                                                                    changeRoute(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <a>
                                                                    <div
                                                                        *ngIf="
                                                                            item
                                                                                .descricaoCor
                                                                                .length >
                                                                            0
                                                                        "
                                                                    >
                                                                        {{
                                                                            item?.descricaoGrade
                                                                        }}
                                                                        -
                                                                        {{
                                                                            item?.descricaoCor
                                                                        }}
                                                                    </div>
                                                                    <div
                                                                        *ngIf="
                                                                            item
                                                                                .descricaoCor
                                                                                .length <=
                                                                            0
                                                                        "
                                                                    >
                                                                        {{
                                                                            item?.descricaoGrade
                                                                        }}
                                                                    </div>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-menu>
                            </div>
                        </div>

                        <!-- CART BUTTON -->
                        <div
                            fxFlex="14"
                            *ngIf="isLogged"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                        >
                            <div
                                [fxFlex.gt-md]="qtdeTotalItens > 0 ? 30 : 22"
                                [fxFlex.gt-sm]="qtdeTotalItens > 0 ? 30 : 22"
                                [fxFlex.gt-xs]="qtdeTotalItens > 0 ? 30 : 22"
                            >
                                <div fxLayoutAlign="center center">
                                    <a
                                        mat-button
                                        (click)="routerTo('/cart')"
                                        class="flex-row-button-pages"
                                    >
                                        <img
                                            src="{{ cartSelecionado }}"
                                            alt="icon-shopping-cart"
                                            class="icons-p"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <!-- MENU BUTTON -->
                        <div
                            fxFlex="10"
                            fxLayoutAlign="center"
                            class="theme-container"
                            style="margin-bottom: 1%"
                        >
                            <button
                                mat-button
                                (click)="sidenav.toggle()"
                                class="sidenav-toggle"
                            >
                                <mat-icon
                                    style="font-size: 32px"
                                    class="color-preto"
                                    >menu</mat-icon
                                >
                            </button>
                        </div>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </ng-template>
    </div>
    <!-- FIM DA HOME MOBILE -->

    <!-- PAGINA WEB -->

    <!-- BARRA TOOLBAR PAGINAS -->
    <div [hidden]="isMobile">
        <div
            class="menuNaoFlutuante"
            style="
                display: flex;
                align-items: center;
                justify-content: space-between;
            "
        >
            <mat-toolbar
                color="primary"
                class="toolbar-color color-preto p-1"
                (mouseover)="changeMenu(true)"
                (mouseout)="changeMenu(false)"
            >
                <!-- LOGO -->
                <mat-toolbar-row
                    fxLayout.sm="row"
                    fxLayoutAlign="space-between center"
                    class="logo-toolbar theme-container"
                >
                    <div
                        (click)="routerTo('/')"
                        fxFlex="85"
                        fxLayout.gt-xs="row"
                        fxLayout.sm="5"
                    >
                        <a (click)="closeSubMenus()">
                            <div *ngIf="!isMobile">
                                <img
                                    id="img-logo"
                                    src="{{ linksLogo.negativo }}"
                                    alt="Logo"
                                />
                            </div>
                        </a>
                    </div>

                    <div
                        id="menuDropdown"
                        fxFlex="50"
                        fxFlex.gt-md="75"
                        fxFlex.gt-sm="75"
                        fxFlex.gt-xs="75"
                        fxShow="false"
                        fxLayout="row"
                        fxShow.gt-sm
                        class="divMenu"
                    >
                        <div *ngFor="let item of menus">
                            <div
                                *ngIf="!item.possuiSubMenu"
                                class="menu-padding"
                            >
                                <a
                                    [ngClass]="{
                                        'font-color-home':
                                            !item.menuSelecionado,
                                        'font-color-selected':
                                            item.menuSelecionado
                                    }"
                                    class="middle"
                                    (click)="menuRouterTo(item)"
                                    >{{ item?.rotulo }}</a
                                >
                            </div>

                            <div
                                *ngIf="item.possuiSubMenu"
                                class="menu-padding"
                            >
                                <a
                                    [ngClass]="{
                                        'font-color-home':
                                            !item.menuSelecionado,
                                        'font-color-selected':
                                            item.menuSelecionado
                                    }"
                                    class="middle"
                                    (click)="
                                        item.menuSelecionado =
                                            !item.menuSelecionado
                                    "
                                    [matMenuTriggerFor]="before"
                                >
                                    <div class="with-icon">
                                        {{ item?.rotulo
                                        }}<i class="material-icons"
                                            >arrow_drop_down</i
                                        >
                                    </div>
                                </a>
                                <mat-menu
                                    #before="matMenu"
                                    xPosition="before"
                                    backdropClass="customize"
                                >
                                    <div *ngFor="let ret of subItems">
                                        <div *ngIf="ret.id === item.id">
                                            <a
                                                *ngFor="let cat of ret?.itens"
                                                class="middle font-color-default"
                                                mat-menu-item
                                                (click)="menuRouterTo(cat)"
                                                >{{ cat.name }}</a
                                            >
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>

                    <!-- SEARCH BUTTON -->
                    <div
                        [ngClass]="{
                            'icons-margin': !isLogged,
                            'icons-margin-withCart': isLogged
                        }"
                        Flex="50"
                        fxFlex.gt-md="10"
                        fxFlex.gt-sm="10"
                        fxFlex.gt-xs="10"
                        fxLayout="row"
                        fxLayoutAlign="space-around center"
                    >
                        <div fxLayoutAlign="center center">
                            <button
                                mat-icon-button
                                [matMenuTriggerFor]="searchMenu"
                                #searchMenuTrigger="matMenuTrigger"
                                class="search-toggle-btn"
                                (click)="setFocusInput()"
                                class="button-with-image"
                            >
                                <img
                                    class="search"
                                    src="https://minori-casa.s3.amazonaws.com/site-images/icons/search.png"
                                    alt="search"
                                />
                            </button>

                            <mat-menu
                                #searchMenu="matMenu"
                                [overlapTrigger]="false"
                                xPosition="before"
                                class="search-dropdown mat-elevation-z8"
                            >
                                <ng-template matMenuContent>
                                    <form
                                        [formGroup]="pesquisaForm"
                                        class="search-form"
                                    >
                                        <input
                                            type="text"
                                            formControlName="valorPesquisa"
                                            placeholder="Search"
                                            name="valorPesquisa"
                                            fxFlex
                                            id="inputPesquisa"
                                        />
                                        <button
                                            mat-mini-fab
                                            (click)="
                                                searchList(
                                                    this.pesquisaForm.controls
                                                        .valorPesquisa.value,
                                                    true
                                                )
                                            "
                                            type="button"
                                            class="search-btn mat-elevation-z0 text-muted"
                                        >
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </form>

                                    <div
                                        style="background-color: white"
                                        fxLayout="row"
                                        *ngIf="lista.length > 0"
                                        class="resultadoPesquisa"
                                        id="divBusca"
                                    >
                                        <div class="rolagemPesquisaMobile">
                                            <table fxFlex>
                                                <tbody>
                                                    <tr
                                                        *ngFor="
                                                            let item of lista
                                                        "
                                                        (click)="zerarLista()"
                                                    >
                                                        <td
                                                            class="wrap-20"
                                                            (click)="
                                                                changeRoute(
                                                                    item
                                                                )
                                                            "
                                                        >
                                                            <a>
                                                                <img
                                                                    [src]="
                                                                        item
                                                                            ?.imagens
                                                                            .length >
                                                                        0
                                                                            ? item
                                                                                  ?.imagens[0]
                                                                                  .url
                                                                            : 'assets/images/others/error-24px.svg'
                                                                    "
                                                                    alt="item.descricao"
                                                                    width="75"
                                                                />
                                                            </a>
                                                        </td>

                                                        <td
                                                            class="wrap-80 padding-left"
                                                            (click)="
                                                                changeRoute(
                                                                    item
                                                                )
                                                            "
                                                        >
                                                            <a>
                                                                <div
                                                                    *ngIf="
                                                                        item
                                                                            .descricaoCor
                                                                            .length >
                                                                        0
                                                                    "
                                                                >
                                                                    {{
                                                                        item?.descricaoGrade
                                                                    }}
                                                                    -
                                                                    {{
                                                                        item?.descricaoCor
                                                                    }}
                                                                </div>
                                                                <div
                                                                    *ngIf="
                                                                        item
                                                                            .descricaoCor
                                                                            .length <=
                                                                        0
                                                                    "
                                                                >
                                                                    {{
                                                                        item?.descricaoGrade
                                                                    }}
                                                                </div>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-menu>
                        </div>

                        <!-- ICON CART -->
                        <div
                            *ngIf="isLogged"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                        >
                            <div
                                [fxFlex.gt-md]="qtdeTotalItens > 0 ? 30 : 22"
                                [fxFlex.gt-sm]="qtdeTotalItens > 0 ? 30 : 22"
                                [fxFlex.gt-xs]="qtdeTotalItens > 0 ? 30 : 22"
                            >
                                <div fxLayoutAlign="center center">
                                    <a
                                        mat-button
                                        (click)="openDialogCart()"
                                        class="flex-row-button"
                                    >
                                        <img
                                            src="{{ cartSelecionado }}"
                                            alt="icon-shopping-cart"
                                            class="icons-p"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- ICON USER -->
                        <app-top-menu
                            fxLayoutAlign="center center"
                            fxShow="false"
                            fxShow.gt-sm
                        ></app-top-menu>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <a
                                [href]="links.wharehouse3D"
                                target="_blank"
                                style="margin-top: 10px"
                            >
                                <img
                                    class="icons-p-wharehouse"
                                    src="../../assets/icons/3DWharehouse.png"
                                />
                            </a>
                        </div>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
    </div>
    <!-- FIM DA WEB -->

    <div class="theme-container">
        <router-outlet></router-outlet>
    </div>

    <div *ngIf="isHome" class="clear"></div>
    <app-footer *ngIf="isHome"></app-footer>

    <!-- <div fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition">
        <a href="https://api.whatsapp.com/send?phone=+13059273050&text=Hey,%20I%20have%20a%20question%20about" class="float"
            target="_blank">
            <i class="fa fa-whatsapp my-float"></i>
        </a>
    </div> -->
</mat-sidenav-container>
