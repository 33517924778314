<div class="main-no-items" *ngIf="carrinho?.itens.length <= 0">
    <mat-chip-list class="mb-2 semItens">
        <div fxFlex="100" fxLayoutAlign="center center">
            <img id="img-logo" src="{{ linksLogo.negativo }}" alt="Logo" />
        </div>
        <div fxFlex="100">
            <h2 selected="true">YOU HAVE NO ITEMS IN YOUR SHOPPING CART</h2>
            <p  selected="true">Go to the home page or search the site for the products you need. When you find them, click on the add to cart button</p>
        </div>
        <div fxFlex="100">
            <a mat-button class="flex-row-button btn" (click)="fecharModal()">
                <mat-icon class="mat-icon-md">navigate_before</mat-icon>
                <div fxLayout="column" fxLayoutAlign="center center"
                    class="top-cart">
                    <span style="font-size: 16px">Back to shopping</span>
                </div>
            </a>
        </div>
    </mat-chip-list>
</div>
<div fxLayout="row" *ngIf="carrinho?.itens.length > 0">
    <div fxFlex="65">
        <div class="p-0 main" fxShow="false" fxShow.gt-sm>
            <div class="aligner">
                <h3 class="title">Cart</h3>
                
                <hr class="line" />
                
                <div class="mat-table cart-table">
                    <div *ngFor="let produto of carrinho?.itens; let index = index" class="mat-row">
                        <!-- img do produto -->
                        <div fxFlex="17" class="mat-cell"><img [src]="produto.url"></div>
                        
                        <!-- descrição do produto -->
                        <div fxFlex="40"> 
                            <div class="mat-cell text-right descricao">
                                <h3>{{produto.descricao}}</h3>
                            </div>
                            <!-- descrição do tamanho do sob medida -->
                            <!-- <div class="mat-cell text-left text-muted descricao" *ngIf="produto.vendaSobMedidaM2 == 1">
                                <span> 
                                    <p> <strong>Comp.:</strong> {{produto.comprimento}}m | <strong>Larg.:</strong> {{produto.largura}}m</p>
                                </span>
                            </div> -->

                            <!-- info dos produtos -->
                            <!-- <div *ngIf="produto.vendaSobMedidaM2 == 1" class="mat-cell text-right descricao">
                                <p> <strong>Preço Medida:</strong> {{ produto.totaIBruto | currency}}</p>
                            </div> -->
                            <div *ngIf="produto.lotes.length == 0 && produto.vendaSobMedidaM2 == 0" class="mat-cell text-right  descricao">
                                <p><strong>Unit Price:</strong> {{ produto.preco | currency }}</p>
                            </div>
                            <div class="mat-cell text-right  descricao">
                                <p><strong>Subtotal:</strong> {{ produto.totaIBruto | currency }}</p>
                            </div>
                            <div class="mat-cell text-right  descricao">
                                <p><strong>Total Item:</strong>  {{produto.totaITem | currency }}</p> 
                            </div>
                        </div>

                        <!-- quantidade sob medida -->
                        <!-- <div fxFlex="30" style="text-align: center;" class="mat-cell text-muted" *ngIf="produto.vendaSobMedidaM2 == 1">{{produto.quantidade}}</div> -->
                        
                        <!-- mecanismo de adicionar e remover itens -->
                        <div fxFlex="30" class="mat-cell text-center text-muted" *ngIf="produto.lotes.length == 0 && produto.vendaSobMedidaM2 == 0">
                            <app-controls class="cart-icons" [product]="produto" [typeMobile]="false" (onQuantityChange)="updateCart($event)" [add]="true"></app-controls>
                        </div>
                        
                        <div fxFlex="13" class="p-1 text-center">
                            <button class="btnExcluirItem" (click)="excluirItem(produto)" matTooltip="Remove">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <hr class="line" />
            </div>
        </div>
        <div class="botoes">
            <div fxFlex="60">
                <a mat-button class="flex-row-button btn" (click)="fecharModal()">
                    <mat-icon class="mat-icon-md">navigate_before</mat-icon>
                    <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs
                        class="top-cart">
                        <span style="font-size: 1rem">Back to shopping</span>
                    </div>
                </a>
            </div>
            <div fxFlex="40">
                <a mat-button class="flex-row-button btn" (click)="excluirCarrinho()" style="margin: 1.25rem 3.625rem">
                    <div class="lixo" fxLayoutAlign="space-around center" >
                        <i style="font-size: 20px;" class="fas fa-trash-alt"></i>
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="end center" fxShow="false" fxShow.gt-xs
                        class="top-cart">
                        <span style="font-size: 1rem">Clean Cart</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div fxFlex="39" class="values" style="border-radius: 15px 0px 0px 15px;">
        <div class="aligner" fxLayout="column" >
            <div fxLayout="row wrap">
                <div fxFlex="86">
                    <h3 class="title-bloco">Summary</h3>
                    <p>{{this.itensReduzidos}} items</p>
                </div>
                <div fxFlex="11">
                    <button class="stock-icon-container" mat-icon-button (click)="fecharModal(true)">
                        <img class="close" src="https://minori-casa.s3.amazonaws.com/site-images/icons/close-brown.png"
                        alt="close">
                    </button>
                </div>
            </div>
            
            <hr class="line-bloco" />

            <div class="itens" style="height: 13.313rem;">
                <!-- <h3 *ngFor="let cat of categoriasReduzidas">
                    <p>{{cat?.tipo | titlecase}}</p>
                    
                    <p>{{cat?.totaITem | currency }}</p>
                </h3> -->
            </div>
        </div>
        
        <div fxLayout="column" fxLayoutAlign="space-between" class="mat-row btnsCart">
            <hr class="line-bloco" />
            <div fxLayout="row" fxFlex="33" class="mat-cell">
                <h3 class="title-bloco"> Total: </h3>
                <span class="title-valor">{{ resumoCarrinho?.total | currency }}</span>
            </div>
            <div style="width: 17.2rem;" fxFlex="33" class="text-center mat-cell">
                <button (click)="checkoutRoute()" mat-raised-button color="primary" class="w-100 checkout">
                    Checkout
                </button>
            </div>
        </div>
    </div>
