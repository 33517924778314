<app-loading [loading]="loading"></app-loading>

<div class="fonte-centaur">
    <!-- <div fxShow="false" fxShow.gt-sm>
        <div *ngFor="let item of dados" class="mt-5 mb-5">
            <div fxLayout.gt-sm="row wrap" class="align-center">

                <div *ngIf="item.lado == 'right'" fxFlex="50" class="vertAlignMidCenter">
                    <div class="d-block">
                        <h1 class="div-produtos">{{item.title}}</h1>
                        <div class="p-quadrado">
                            <p>{{item.texto}}</p>
                            <div class="mt-5">
                                <a style="text-decoration: none;color:#000;" *ngIf="item.tipoLink == 'href'" [href]="item.link" target="_blank" class="middle">{{item.labelBtn}}</a>
                                <a *ngIf="item.tipoLink != 'href'" class="middle" (click)="goTo(item)">{{item.labelBtn}}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex="50" class="mt-2 mb-2">
                    <a *ngIf="item.tipoLink == 'href'" [href]="item.link" fxLayoutAlign="center center" class="image-link text-truncate w-100">
                        <img loading="lazy" [src]="item.url" class="tamanho-img" [alt]="item.descricaoImagem" />
                    </a>
                    <a *ngIf="item.tipoLink != 'href'" (click)="goTo(item, 'divConosco')" fxLayoutAlign="center center" class="image-link text-truncate w-100">
                        <img loading="lazy" [src]="item.url" class="tamanho-img" [alt]="item.descricaoImagem" />
                    </a>
                </div>

                <div *ngIf="item.lado == 'left'" fxFlex="50" class="vertAlignMidCenter">
                    <div class="d-block">
                        <h1 class="div-produtos">{{item.title}}</h1>
                        <div class="p-quadrado">
                            <p>{{item.texto}}</p>
                            <div class="mt-5">
                                <a style="text-decoration: none;color:#000;" *ngIf="item.tipoLink == 'href'" [href]="item.link" target="_blank" class="middle">{{item.labelBtn}}</a>
                                <a *ngIf="item.tipoLink != 'href'" class="middle" (click)="goTo(item)">{{item.labelBtn}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


    <!-- mobile -->

    <div fxShow="false" fxShow.lt-sm fxShow.lt-md class="main">
        
        <div class="support-scrollsnap"></div>

        <div class="scroll-container">
            <div (click)="goToFixedLink('products/tapetes/000151/2')" class="scroll-area" [ngStyle]="{
                    backgroundImage: 'url(https://minori-casa.s3.amazonaws.com/site-images/home/home-mobile-tapetes.png)',
                    backgroundSize: 'cover'}" fxLayoutAlign="center stretch">
                    <div class="content">
                        <h2 [routerLink]="['products/tapetes/000151/2']">
                            Rugs
                        </h2>
                        <p [routerLink]="['products/tapetes/000151/2']">
                            Elegance and sophistication <br>
                            for every project.
                        </p>
                        <a>
                            See more
                        </a>
                    </div>
            </div>
            <!-- <div (click)="goToFixedLink('products/tecidos/000097/2')" class="scroll-area" [ngStyle]="{
                    backgroundImage: 'url(https://minori-casa.s3.amazonaws.com/site-images/home/home-mobile-tecidos.png)',
                    backgroundSize: 'cover'}" fxLayoutAlign="center stretch">
                    <div class="content">
                        <h2 [routerLink]="['products/tecidos/000097/2']">
                            Tecidos
                        </h2>
                        <p [routerLink]="['products/tecidos/000097/2']">
                            Performances para ambientes<br>
                            Outdoor e Indoor.
                        </p>
                        <a>
                            Veja mais
                        </a>
                    </div>
            </div> -->
            <!-- <div (click)="goToFixedLink('/products/tapetes/000605/3')" class="scroll-area" [ngStyle]="{
                    backgroundImage: 'url(https://minori-casa.s3.amazonaws.com/site-images/home/home-mobile-lancamentos.png)',
                    backgroundSize: 'cover'}" fxLayoutAlign="center stretch">
                    <div class="content">
                        <h2 style="font-size: 2.6rem;">
                            Lançamentos <br>
                            2023
                        </h2>
                        <p style="margin: 5rem 0;">
                            Conheça as novas tendências<br>
                            de 2023/24
                        </p>
                        <a>
                            Veja mais
                        </a>
                    </div>
            </div> -->
        </div>


        <!-- <div *ngFor="let item of dados">
            <div class="support-scrollsnap"></div>

            <div class="scroll-container">
                <img class="scroll-area" [src]="item.url" />
            </div>
        </div> -->
    </div>
</div>

<!-- </div> -->

<!-- <figure id="container" (click)="goTo(item)">
    <img [src]="item.url" />  
    <figcaption>{{item?.title}}</figcaption>
       <a *ngIf="item.tipoLink == 'href'" [href]="item.link" target="_blank" class="myButton">
            <p>
                {{item?.labelBtn}}
            </p>
        </a>
        <a *ngIf="item.tipoLink != 'href'" fxShow="false" fxShow.lt-md class="myButton" (click)="goTo(item)">
            <p>
                {{item?.labelBtn}}
            </p>
        </a>
</figure> -->