import { Location } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatAccordion } from "@angular/material/expansion";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AppService } from "src/app/app.service";
import {
    CarrinhoRetorno,
    CarrinhoRetornoItens,
    CartService,
    ResumoCarrinho,
} from "src/app/services/cart/cart.service";
import { SessionService } from "src/app/services/session/session.service";
import { UtilService } from "src/app/services/util/util.service";

@Component({
    selector: "app-modal-cart",
    templateUrl: "./modal-cart.component.html",
    styleUrls: ["./modal-cart.component.scss"],
})
export class ModalCartComponent implements OnInit {
    public linksLogo = {
        positivo: "../../assets/images/logo/logo-branco.svg",
        negativo:
            "https://www.minoricasa.com/assets/images/logo/logo-bege-escuro.png",
        preto: "../../assets/images/logo/logo-preto.svg",
        mobile: "https://maioricasa.s3.amazonaws.com/logos/m-maioricasa-marrom.svg",
    };

    total = [];
    grandTotal = 0;
    cartItemCount = [];
    cartItemCountTotal = 0;

    public itensReduzidos: number = 0;

    codCliente: string;
    public carrinho: CarrinhoRetorno;
    private itensOriginal: Array<CarrinhoRetornoItens> = [];
    codTabelaPreco: string;
    resumoCarrinho: ResumoCarrinho;
    loading: boolean = false;

    @ViewChild(MatAccordion) accordion: MatAccordion;

    public statusCarrinho: boolean = true;
    public categoriasReduzidas = [];

    isOpen: boolean = false;
    public carr: boolean = false;

    constructor(
        public appService: AppService,
        private cartService: CartService,
        private sessionService: SessionService,
        private snackBar: MatSnackBar,
        private router: Router,
        public location: Location,
        public dialogRef: MatDialogRef<ModalCartComponent>,
        private utilService: UtilService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.sessionService.information$.subscribe(
            (data) => {
                this.codCliente = data.codCliente;
                this.codTabelaPreco = data.codTabelaPreco;
            },
            (error) => {
                console.log("Error no sesssionService");
            }
        );
        this.getObterCarrinho();

        this.carrinho = this.data;
        console.log(this.carrinho);

        if (this.carrinho.itens.length > 0) {
            this.carrinho.temCarrinho = true;
            this.categoriasReduzidas =
                this.utilService.reduceArrayAndGroupByProperty(
                    this.carrinho.itens,
                    "tipo",
                    "totaITem"
                );
            this.itensReduzidos = this.utilService.reduceArrayByProperty(
                this.carrinho.itens,
                "quantidade"
            );
        } else {
            this.carrinho.temCarrinho = false;
        }
        this.getResumoCarrinho();

        this.cartService.emitCart.pipe().subscribe((data) => {
            if (this.router.url.indexOf("cart") != -1 && data == "excluir") {
                this.getObterCarrinho();
            }
        });
    }

    public updateCart(event) {
        // setTimeout(() => this.getObterCarrinho(), 1000);
        this.getObterCarrinho();
    }

    public getResumoCarrinho() {
        this.loading = true;
        this.cartService.resumoCarrinho(this.codCliente).subscribe(
            (item) => {
                this.loading = false;
                this.resumoCarrinho = item;
            },
            (error: HttpErrorResponse) => {
                console.log("error: ", error.error["mensagem"]);
                this.loading = false;
            }
        );
    }

    public getObterCarrinho() {
        this.loading = true;
        this.cartService.obterCarrinho(this.codCliente).subscribe(
            (data) => {
                console.log(data, "DATA");

                this.itensOriginal = [];
                // if ('itens' in data) {
                if (data.itens) {
                    if (data.itens.length > 0) {
                        this.carrinho.temCarrinho = true;
                        console.log(this.carrinho.temCarrinho);

                        let itens: Array<CarrinhoRetornoItens> = [];

                        data.itens.forEach((item) => {
                            this.itensOriginal.push(item);

                            if (item.vendaSobMedidaM2 == 1) {
                                item.quantidade = 1;
                            }
                            let duplicated =
                                itens.findIndex((redItem) => {
                                    if (
                                        item.codProduto == redItem.codProduto &&
                                        item.vendaSobMedidaM2 != 1 &&
                                        item.lotes.length == 0
                                    ) {
                                        redItem.quantidade += item.quantidade;
                                        return item;
                                    }
                                }) > -1;

                            if (
                                item.lotes.length > 0 &&
                                item.descricao.indexOf("PECA") != -1
                            ) {
                                item.descricao += ` - ${item.quantidade.toFixed(
                                    2
                                )} mts`;
                            }

                            if (!duplicated) {
                                itens.push(item);
                            }
                        });
                        data.itens = itens;
                        this.carrinho = data;
                        console.log(this.carrinho);
                        this.itensReduzidos =
                            this.utilService.reduceArrayByProperty(
                                this.carrinho.itens,
                                "quantidade"
                            );
                        this.categoriasReduzidas =
                            this.utilService.reduceArrayAndGroupByProperty(
                                this.carrinho.itens,
                                "tipo",
                                "totaITem"
                            );

                        this.getResumoCarrinho();
                    } else {
                        this.carrinho = { itens: [], temCarrinho: false };
                    }
                } else {
                    this.carrinho = { itens: [], temCarrinho: false };
                }
                this.loading = false;
            },
            (error: HttpErrorResponse) => {
                this.carrinho = { itens: [], temCarrinho: false };
                this.loading = false;
            }
        );
    }

    public excluirCarrinho() {
        if (this.carrinho.itens.length <= 0) {
            console.log("Carrinho vazio. Nenhum item para remover.");
            return;
        }

        const promises = this.carrinho.itens.map((item) =>
            this.excluirItem(item)
        );

        Promise.all(promises)
            .then(() => {
                this.snackBar.open("Item(s) removed from your cart!", "", {
                    panelClass: "success",
                    verticalPosition: "bottom",
                    duration: 2000,
                });
                this.getObterCarrinho();
            })
            .catch((error) => {
                console.error("Erro ao remover itens:", error);
            });
    }

    public async excluirItem(item: CarrinhoRetornoItens) {
        this.loading = true;
        let fil: Array<CarrinhoRetornoItens> = this.itensOriginal.filter(
            (x) => x.codProduto == item.codProduto
        );
        try {
            const results = await Promise.all(
                fil.map(async (filteredItem) => {
                    await this.cartService
                        .excluirItem(
                            this.codCliente,
                            filteredItem.id,
                            filteredItem.idProduto,
                            filteredItem.codProduto
                        )
                        .toPromise();

                    return `${filteredItem.descricao} was removed from your cart!`;
                })
            );

            //   console.log(results);

            //   if (results.length > 1) {
            //     this.snackBar.open('Items removed from your cart!', '', {
            //       panelClass: 'success',
            //       verticalPosition: 'bottom',
            //       duration: 2000,
            //     });
            //   } else {
            //     this.snackBar.open(results[0], '', {
            //       panelClass: 'success',
            //       verticalPosition: 'bottom',
            //       duration: 2000,
            //     });
            //   }

            this.loading = false;
        } catch (error) {
            console.error("Erro ao remover itens:", error);
            this.snackBar.open(
                `Error removing items from your shopping cart. Error: ${error.error}`,
                "",
                {
                    panelClass: "warn",
                    verticalPosition: "bottom",
                    duration: 2000,
                }
            );
            this.loading = false;
        }
    }

    public backToProductsRoute() {
        this.loading = true;
        this.location.back();
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }

    public formatterValue(valor) {
        let val = (valor / 1).toFixed(2).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    public checkoutRoute() {
        this.router.navigate(["/checkout"]);
        this.dialogRef.close();
    }

    fecharModal(atualizarItens: boolean) {
        this.dialogRef.close(atualizarItens);
    }
}
