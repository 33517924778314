import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SelectOptions } from 'src/app/interfaces/select-options.model';
import { CarrinhoEnvioItem, CarrinhoRetorno, CarrinhoRetornoItens, CartService, ResumoCarrinho } from 'src/app/services/cart/cart.service';
import { ProdutoService, RetornoDados } from 'src/app/services/produto/produto.service';
import { SessionService } from 'src/app/services/session/session.service';
import { UtilService } from 'src/app/services/util/util.service';
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-modal-sob-medida',
  templateUrl: './modal-sob-medida.component.html',
  styleUrls: ['./modal-sob-medida.component.scss']
})
export class ModalSobMedidaComponent implements OnInit {
    public linksLogo = {
        positivo: "https://maioricasa.s3.amazonaws.com/logos/maioricasa-branco.svg",
        negativo: "https://maioricasa.s3.amazonaws.com/logos/maioricasa-marrom.svg",
        preto: "https://maioricasa.s3.amazonaws.com/logos/maioricasa-preto.svg",
        mobile: "https://maioricasa.s3.amazonaws.com/logos/m-maioricasa-marrom.svg",
      };
    
      public itensReduzidos: number = 0;
    
      total = [];
      grandTotal = 0;
      cartItemCount = [];
      cartItemCountTotal = 0;
    
      codCliente: string;
      carrinho: CarrinhoRetorno;
      private itensOriginal: Array<CarrinhoRetornoItens> = [];
      codTabelaPreco: string;
      resumoCarrinho: ResumoCarrinho;
      loading: boolean = false;
      @ViewChild(MatAccordion) accordion: MatAccordion;
    
      public statusCarrinho: boolean = true;
    
      public produtos;
      public productItems;
      private corProduto: string = "";
    
      sobMedidaForm: FormGroup;
    
      // public selectBorda: Array<SelectOptions> = [
      //   { label: "Feito à mão", value: "3" },
      // ];
    
      // public selectFormato: Array<SelectOptions> = [
      //   { label: "Retangular", value: "0" },
      //   { label: "Redondo", value: "1" },
      //   { label: "Quadrado", value: "2" },
      //   { label: "Orgânico", value: "3" },
      // ];
    
      public selectBorda: Array<SelectOptions> = [
        { label: "Overlock 4 Lados", value: "0" },
        { label: "Borda virada nos 4 lados", value: "2" },
        { label: "Borda virada na largura e overlock comprimento", value: "1" },
      ];
    
      public selectFormato: Array<SelectOptions> = [
        { label: "Retangular", value: "0" },
        { label: "Redondo", value: "1" },
        { label: "Quadrado", value: "2" },
      ];
    
      public valoresInputs: any;
      tamanhoSelecionado: {};
      public dados: RetornoDados;
      valorTotal: number;
      produtoSobMedida: any;
    
      dadosValidos: boolean = false;
      dadosAlterados: boolean = false;
      validarDados: boolean = false;
      public isQuadrado = false;
    
      constructor(
        public appService: AppService,
        private cartService: CartService,
        private sessionService: SessionService,
        private snackBar: MatSnackBar,
        private router: Router,
        public location: Location,
        public dialogRef: MatDialogRef<ModalSobMedidaComponent>,
        private utilService: UtilService,
        private fb: FormBuilder,
        private produtoService: ProdutoService,
        @Inject(MAT_DIALOG_DATA) public data: any
      ) {}
    
      ngOnInit() {
        this.sessionService.information$.subscribe(
          (data) => {
            this.codCliente = data.codCliente;
            this.codTabelaPreco = data.codTabelaPreco;
          },
          (error) => {
            console.log("Error no sesssionService");
          }
        );
    
        this.sobMedidaForm = this.fb.group({
          comprimento: new FormControl(
            { value: "", disabled: false },
            Validators.compose([Validators.required, Validators.maxLength(5)])
          ),
          largura: new FormControl(
            { value: "", disabled: false },
            Validators.compose([Validators.required])
          ),
          comprimentoValido: new FormControl({ value: false }),
          larguraValida: new FormControl({ value: false }),
          borda: new FormControl(
            { value: "3", disabled: true },
            Validators.compose([Validators.required])
          ),
          formato: new FormControl(
            { value: "", disabled: false },
            Validators.compose([Validators.required])
          ),
        });
    
        // this.sobMedidaForm.valueChanges.subscribe((data) => {
        //   console.log(data);
        //   this.validaDados();
        // });
    
        this.sobMedidaForm.valueChanges
          .pipe(debounceTime(500))
          .subscribe((data) => {
            this.dadosAlterados = true;
            if (
              this.controls.comprimentoValido.value &&
              this.controls.larguraValida.value
            ) {
              this.validaDados();
            }
          });
    
        this.controls["largura"].valueChanges
          .pipe(debounceTime(600))
          .subscribe((data) => {
            this.isInputValid(data, "largura");
          });
    
        this.controls["comprimento"].valueChanges
          .pipe(debounceTime(600))
          .subscribe((data) => {
            if (this.isQuadrado) {
              this.controls["largura"].setValue(data);
            }
            this.isInputValid(data, "comprimento");
          });
    
        this.controls["formato"].valueChanges.subscribe((data) => {
          if (data == 2) {
            this.isQuadrado = true;
          } else {
            this.isQuadrado = false;
          }
          this.controls["largura"].reset();
          this.controls["comprimento"].reset();
        });
    
        // this.produtos = this.data;
        // console.log(this.produtos);
        this.getProductById(this.data.chaveErp);
      }
    
      get controls() {
        return this.sobMedidaForm.controls;
      }
    
      private isInputValid(value: number, field: string): void {
        let resultado: number = (value * 100) % 5;
        if (resultado != 0) {
          if (field === "comprimento") {
            this.controls.comprimentoValido.setValue(false);
          } else {
            this.controls.larguraValida.setValue(false);
          }
    
          this.controls[field].setValue(0);
          this.snackBar.open(`Value of ${field} invalid`, "", {
            panelClass: "error",
            verticalPosition: "bottom",
            duration: 3000,
          });
        } else {
          if (field === "comprimento") {
            this.controls.comprimentoValido.setValue(true);
          } else {
            this.controls.larguraValida.setValue(true);
          }
        }
      }
    
      public backToProductsRoute() {
        this.loading = true;
        this.location.back();
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    
      public formatterValue(valor) {
        let val = (valor / 1).toFixed(2).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    
      public checkoutRoute() {
        this.dialogRef.close();
        this.router.navigate(["/checkout"]);
      }
    
      public resetaInput(input: string, event) {
        if (input == "largura" && event.target.valueAsNumber === 0) {
          this.controls[input].reset();
        } else if (input == "comprimento" && event.target.valueAsNumber === 0) {
          this.controls[input].reset();
        } else if (input == "lado" && event.target.valueAsNumber === 0) {
          this.controls["largura"].reset();
          this.controls["comprimento"].reset();
        }
      }
    
      fecharModal(atualizarItens: boolean) {
        this.dialogRef.close(atualizarItens);
      }
    
      getValuesInputs() {
        var produto = {
          cor: "this.corProduto" ?? "",
          largura: this.controls["largura"].value,
          comprimento: this.controls["comprimento"].value,
          formato:
            this.controls["formato"].value == "2"
              ? "0"
              : this.controls["formato"].value,
          borda: this.controls["borda"].value,
        };
    
        return produto;
      }
    
      setPreco(valuesInput, produto) {
        const sobMedida = produto.filter(
          (item) => item.codProduto === this.data.chaveErp
        );
        if (sobMedida.length > 0) {
          let preco = 0;
          var bordaX = 0.0;
          var bordaY = 0.0;
    
          if (valuesInput.formato == "0") {
            if (valuesInput.borda == "1") {
              var bordaX = 0.1;
              var bordaY = 0.0;
            } else if (valuesInput.borda == "2") {
              var bordaX = 0.1;
              var bordaY = 0.1;
            } else {
              var bordaX = 0.0;
              var bordaY = 0.0;
            }
    
            preco =
              (parseFloat(valuesInput.comprimento) + bordaX) *
              (parseFloat(valuesInput.largura) + bordaY) *
              sobMedida[0].preco;
          } else {
            preco =
              parseFloat(valuesInput.comprimento) *
              parseFloat(valuesInput.largura) *
              sobMedida[0].preco;
          }
    
          preco = preco + preco * (sobMedida[0].percentualIpi / 100);
          this.valorTotal = preco;
        }
      }
    
      public getProductById(chaveErp: string) {
        // this.loading = true;
        this.produtoService.getProduto(chaveErp, this.codTabelaPreco).subscribe(
          (data) => {
            console.log(data);
    
            this.produtos = data.dados.produtos;
            if (this.produtos.length > 0) {
              this.corProduto = this.produtos[0].codigoCor;
            }
            this.controls["comprimento"].setValue("0", { emitEvent: false });
            this.controls["largura"].setValue("0", { emitEvent: false });
            this.controls["formato"].setValue("0", { emitEvent: false });
            this.controls["borda"].setValue("0", { emitEvent: false });
            this.valoresInputs = this.getValuesInputs();
    
            this.setPreco(this.valoresInputs, this.produtos);
            let ret = this.retornaDados(this.produtos, this.corProduto);
            console.log(ret);
    
            ret.map((data: RetornoDados) => {
              this.dados = data;
            });
    
            console.log(this.dados);
            // this.valorTotal = this.dados.preco;
    
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            console.error(err);
          }
        );
        // this.loading = false;
      }
    
      retornaDados(produtos, codCor) {
        for (let index = 0; index < produtos.length; index++) {
          if (
            produtos[index].codigoCor == codCor &&
            produtos[index].vendaSobMedidaM2 == 1
          ) {
            var produto = produtos[index];
            this.tamanhoSelecionado = produtos[index].codigoTamanho;
            this.produtoSobMedida = produtos[index];
          }
        }
    
        var dados = [];
    
        var ret = dados.indexOf(
          dados.filter(function (dado) {
            return dado.codigoTamanho == produto.codigoTamanho;
          })[0]
        );
    
        if (ret === -1) {
          dados.push({
            comprimento: produto.comprimento,
            largura: produto.largura,
            codigoCor: produto.codigoCor,
            descricao: produto.descricao,
            codProduto: produto.codProduto,
            preco: produto.preco,
            descricaoCor: produto.descricaoCor,
            estoqueProduto: produto.estoqueProduto,
            codigoTamanho: produto.codigoTamanho,
            percentualIpi: produto.percentualIpi,
            idProduto: produto.idProduto,
            foraDeLinha: produto.foraDeLinha,
            previsaoEntrega: produto.previsaoEntrega,
            publicadoProduto: produto.publicadoProduto,
            vendaSobMedidaM2: produto.vendaSobMedidaM2,
            qtdLimitePedidoAcimaEstoque: produto.qtdLimitePedidoAcimaEstoque,
            descricaoTamanho: produto.descricaoTamanho,
            categorias: produto.categorias,
          });
        }
        return dados;
      }
    
      validaDados() {
        this.loading = true;
        this.validarDados = false;
        let sobmedida = {
          codigoProduto: this.data.chaveErp,
          largura: this.controls["largura"].value,
          comprimento: this.controls["comprimento"].value,
          tipoBorda: this.controls["borda"].value,
          redondo: this.controls["formato"].value == "2" ? "0" : "1",
        };
        console.log(sobmedida);
        this.produtoService.validaSobMedida(sobmedida).subscribe(
          (data: any) => {
            if (data.status === 1) {
              this.snackBar.open(data.mensagem, "", {
                panelClass: "error",
                verticalPosition: "bottom",
                duration: 5000,
              });
              this.dadosValidos = false;
              this.loading = true;
            } else {
              this.valoresInputs = this.getValuesInputs();
              this.setPreco(this.valoresInputs, this.produtos);
              this.controls["comprimento"].setValue(data.comprimento, {
                emitEvent: false,
              });
              this.controls["largura"].setValue(data.largura, { emitEvent: false });
              this.dadosValidos = true;
              this.dadosAlterados = false;
              this.loading = true;
            }
          },
          (err) => {
            this.loading = false;
            console.error(err);
          }
        );
      }
    
      public addToCart() {
        this.validaDados();
        if (this.dadosValidos === true) {
          console.log("produtos carrinho", this.produtoSobMedida);
          let idSessao: string;
          let usuario: string;
          this.sessionService.information$.subscribe((data) => {
            idSessao = data.token;
            usuario = data.email;
          });
          const quantidadeM2 =
            this.controls["comprimento"].value * this.controls["largura"].value;
          const quantidadeM2Fixed = quantidadeM2.toFixed(2);
    
          let item: CarrinhoEnvioItem = {
            codProduto: this.data.chaveErp,
            comprimento: this.controls["comprimento"].value,
            id: 0,
            idProduto: this.produtoSobMedida.idProduto.toString(),
            largura: this.controls["largura"].value,
            lotes: [],
            precoUnit: this.produtoSobMedida.preco,
            quantidade: quantidadeM2Fixed,
            redondo: parseInt(this.controls["formato"].value),
            tipoBorda: parseInt(this.controls["borda"].value),
          };
    
          let obj: any = {
            codCliente: this.codCliente,
            idSessao: idSessao,
            usuario: usuario,
            itens: [item],
          };
          console.log(obj);
          this.cartService.insertCarrinho(obj).subscribe(
            (data) => {
              this.snackBar.open(
                "Produto adicionado ao carrinho com sucesso!",
                "",
                {
                  panelClass: "success",
                  verticalPosition: "bottom",
                  duration: 3000,
                }
              );
              this.fecharModal(false);
            },
            (error: HttpErrorResponse) => {
              this.snackBar.open("Erro!", error.error["mensagem"], {
                panelClass: "error",
                verticalPosition: "bottom",
                duration: 3000,
              });
              console.error(error.error["mensagem"]);
            }
          );
        }
      }
    }
    