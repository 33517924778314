<div fxLayout="row" fxLayoutAlign="center center" class="h-100">
    <div fxFlex="80" fxFlex.gt-sm="40" fxFlex.sm="60" class="text-center">

        <a class="logo">
            <img src={{dadosEmpresa?.urlLogoNegativo}} alt="Logo da marca" width="350" />
        </a>

        <h1 class="error"> Sorry, Page Not Found</h1>
        <h3 class="box-text mt-1">The page you requested could not be found</h3>
        <!-- <h1 class="error"> Oops, Under Construction!</h1>
        <h3 class="box-text mt-1">Sorry we're doing some work on the site. Thank you for being patient.</h3> -->
        <button mat-raised-button color="secondary" class="mat-elevation-z8 mt-3" id="btnNotFound" type="button" (click)="goHome()">
            <mat-icon>chevron_left</mat-icon> Back to Home
        </button>

    </div>
</div>