import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RedesSociaisFooter } from 'src/app/interfaces/redes-sociais-footer.model';
import { FaleConoscoEnvio, FaleConoscoService } from 'src/app/services/fale-conosco/fale-conosco.service';
import { UtilService } from 'src/app/services/util/util.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-modal-contact',
  templateUrl: './modal-contact.component.html',
  styleUrls: ['./modal-contact.component.scss']
})
export class ModalContactComponent implements OnInit {

  public registerForm: FormGroup;
  public isMobile = false;
  public clientType: Array<any> = [{ label: 'Retailer', value: 1 , selected: true }, { label: 'Designer', value: 2 , selected: false }, { label: 'End User', value: 3 , selected: false }];

  public redesBase: RedesSociaisFooter = {
    // youtube: 'https://www.youtube.com/channel/UC_XCs9L_jTHlQou4VIyWyew/videos',
    facebook: 'https://www.facebook.com/minoricasa/',
    pinterest: 'https://pinterest.com/Minoricasa/',
    instagram: 'https://www.instagram.com/minoricasa/',
    whatsapp: 'https://api.whatsapp.com/send?phone=+13059273050&text=Hey,%20I%20have%20a%20question%20about'
  };

  constructor(
    public dialogRef: MatDialogRef<ModalContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private faleConoscoService: FaleConoscoService,
    private snackBar: MatSnackBar,
    private utilService: UtilService) {
    this.isMobile = screen.width > 768 ? false : true;
}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      nome: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      ddd: [''],
      telefone: ['', Validators.compose([Validators.required])],
      mensagem: ['', [Validators.required]],
      companyName: [''],
      // assunto: ['', [Validators.required]],
      idTipoCliente: [1, [Validators.required]]
    });
  }

  public verifyFormAndSave(form: any) {
    const invalidForm = this.utilService.findInvalidControls(form.controls);
    if (invalidForm.length > 0) {
      invalidForm.map((data) => {
        const labels = [
          {
            idTipoCliente: 'Client Type',
            nome: 'Full Name',
            email: 'E-mail',
            telefone: 'Phone',
            assunto: 'Subject',
            mensagem: 'Message'
          },
        ];
        labels.forEach((item: any) => {
          Object.keys(item).forEach((valor: any) => {
            if (valor === data) {
              return this.snackBar.open(`Field: ${item[data]} is invalid, please check and try again!`, '', {
                panelClass: 'warn', verticalPosition: 'bottom', duration: 2000
              });
            }
          });
        });
      });
    } else {
      this.enviarForm(form.value)      
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  public selectItem (evt:any) {
    this.clientType = this.clientType.map((item)=>{
        return {
            ...item,
            selected : item.value === evt.value ? true : false
        }
    }); 
}

  private enviarForm(form) {
    let obj: FaleConoscoEnvio;
    obj = {
      url: '/../../assets/images/logo/logo-minori-positivo.png',
      nome: form.nome,
      ddd: form.ddd,
      telefone: form.telefone,
      email: form.email,
      mensagem: form.mensagem,
      assunto: form.assunto,
      idTipoCliente: form.idTipoCliente,
      destinatarios: [
        { email: 'contact@minoricasa.com' }
      ]
    };
    console.log(obj);

    this.faleConoscoService
      .enviarEmailFaleConosco(obj)
      .subscribe((data) => {
        this.snackBar.open(`Thank you for your enquiry
          Your message has been sent successfully. It has been forwarded to the relevant department and will be dealt with as soon as possible.`, '', {
          panelClass: 'success', verticalPosition: 'bottom', duration: 4000
        });
        this.registerForm.reset();
      },
        (error: HttpErrorResponse) => {
          this.snackBar.open(`Error sending the e-mail`, '', {
            panelClass: 'warn', verticalPosition: 'bottom', duration: 2000
          });
        });
  }
}
