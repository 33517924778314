import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { CustomErrorStateMatcher } from 'src/app/pages/open-trade-account/open-trade-account/open-trade-account.component';
import { ObterIdentificador } from 'src/app/services/sign-in/obter-identificador.service';
import { ListaEstados, ListaEstadosService } from 'src/app/services/lista-estados/lista-estados.service';
import { ListaPaises, ListaPaisesService } from 'src/app/services/lista-paises/lista-paises.service';
import { SelectOptions } from 'src/app/interfaces/select-options.model';
import { Router } from '@angular/router';
import { Login, SessionService } from 'src/app/services/session/session.service';
import {  MatDialogRef } from '@angular/material/dialog';
import { RecuperarSenhaService } from 'src/app/services/sign-in/recuperar-senha.service';
// const { version: appVersion } = require('../../../../../package.json');

@Component({
  selector: 'app-modal-sign-in',
  templateUrl: './modal-sign-in.component.html',
  styleUrls: ['./modal-sign-in.component.scss']
})

export class ModalSignInComponent implements OnInit {

    errorMatcher = new CustomErrorStateMatcher();
    public loginForm: FormGroup;
    public hide = true;
    public disabled = true;
    public dadosEmpresa: ObterIdentificador;
    public loading = false;
    public resaleCertificate: File;
    public isMobile = false;
    public registerForm: FormGroup;
    public states: Array<ListaEstados> = [];
    public countries: Array<ListaPaises> = [];
    public version;
    public divforgotPassword = false as boolean;
    esqueciSenhaForm: FormGroup;
    count: number = 0;

  
    public businessType: Array<SelectOptions> = [
      { label: 'E-commerce only', value: '000003' },
      { label: 'Interior Designer', value: '000006' },
      { label: 'Retailer', value: '000007' }
    ];
  
    public salesTaxStatus: Array<SelectOptions> = [
      { label: 'Non-exempt', value: '1' },
      { label: 'Exempt', value: '2' },
      { label: "I'll send by e-mail", value: '3' },
    ];
  
    public minoriCasaRep: Array<SelectOptions> = [
      { label: 'No', value: '1' },
      { label: 'Yes', value: '2' }
    ];
  
    public referenceResource: Array<SelectOptions> = [
      { value: 'Representative', label: 'Representative' },
      { value: 'Web search', label: 'Web search' },
      { value: 'Tradeshow', label: 'Tradeshow' },
      { value: 'Social Media', label: 'Social Media' },
      { value: 'E-mail', label: 'E-mail' },
      { value: 'Magazine Add', label: 'Magazine Add' },
      { value: 'Word of mouth', label: 'Word of mouth' },
      { value: 'Other', label: 'Other (please fill in box)' }
    ];
  
    constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private sessionService: SessionService,
      private snackBar: MatSnackBar,
      private recuperarSenhaService: RecuperarSenhaService,
      private listaEstadosService: ListaEstadosService,
      private listaPaisesService: ListaPaisesService,
      public dialogRef: MatDialogRef<ModalSignInComponent>,
    ) {
      this.sessionService.identificadorUrl();
      this.dadosEmpresa = JSON.parse(localStorage.getItem('identificador'));
      this.isMobile = screen.width > 768 ? false : true;
    //   this.version = appVersion
    }
  
    ngOnInit() {
      this.loginForm = this.formBuilder.group({
        email: ['', Validators.compose([Validators.required, emailValidator])],
        senha: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(12)])]
      });
  
      const validUrl = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
      this.registerForm = this.formBuilder.group({
        A1_NOME: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
        firstName: ['', Validators.compose([Validators.required, Validators.maxLength(25)])],
        lastName: ['', Validators.compose([Validators.required, Validators.maxLength(25)])],
        A1_HPAGE: ['', Validators.compose([Validators.required, Validators.pattern(validUrl)])],
        A1_CODSEG: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        A1_EMAIL: ['', Validators.compose([Validators.required, Validators.maxLength(50), emailValidator])],
        A1_TEL: ['', Validators.compose([Validators.required,
        Validators.minLength(10),
        Validators.maxLength(16)])],
        A1_EST: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(2)])],
        stateDescription: ['', Validators.compose([Validators.required])],
        A1_PAIS: ['', Validators.compose([Validators.required, Validators.maxLength(3)])],
        countryDescription: ['',],
        password: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(12)])],
        resaleCertificateNumber: [''],
        salesTaxStatus: ['1', Validators.required],
        minoriCasaRep: ['1', Validators.required],
        salesman: ['', []],
        referenceResource: ['', Validators.required],
        otherReferenceResource: ['', []],
        urlResaleCertificate: ['', []],
        A1_END: ['', Validators.required],
        A1_CEP: ['', Validators.compose([
          Validators.required, Validators.minLength(1), Validators.maxLength(8), Validators.pattern(/[0-9]/g)])],
        A1_MUN: ['', Validators.compose([Validators.required, Validators.pattern('^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$')])]
      });
  
      this.sessionService.isLogged$.subscribe((data) => {
        if (data && this.router.url === '/login') {
          this.router.navigate(['/']);
        }
      });
  
      this.loginForm.valueChanges.subscribe((_) => {
        this.disabled = this.loginForm.invalid;
      });

      this.esqueciSenhaForm = this.formBuilder.group({
        'email': ['', Validators.compose([Validators.required, emailValidator])],
      });
  
      this.esqueciSenhaForm.valueChanges.subscribe((_) => {
        this.disabled = this.esqueciSenhaForm.invalid;
      })
  
      this.sessionService.isLogged$.subscribe((data) => {
        if (data && this.router.url == '/forgot-password') {
          this.router.navigate(['/']);
        }
      })
  
      this.listarPaises();
      // this.states = this.listaEstadosService.listaEstados();
  
    }
  
    get registerControls() {
      return this.registerForm.controls;
    }
  
    ngOnChanges() {
      this.isMobile = screen.width > 768 ? false : true;
    }
  
    addItem(newItem: File) {
      this.resaleCertificate = newItem;
      console.log(this.resaleCertificate);
    }
  
    onLoginFormSubmit(form: Login): void {
      if (this.loginForm.invalid) {
        return;
      } else {
        this.loading = true;
        this.sessionService.login(form)
          .subscribe((data) => {
            if (data) {
              if (data.codTabelaPreco) {
                localStorage.setItem('codTabelaPreco', data.codTabelaPreco);
                localStorage.setItem('information', JSON.stringify(data));
                this.sessionService.isLoggedIn$.next(true);
                this.sessionService.emitLogin.emit(true);
                this.closeModal()
                this.router.navigate(['']);
                this.loading = false;
              } else {
                const errorMessage = `Your registration needs to be reviewed, please contact our team`;
                this.snackBar.open(errorMessage,
                  '', { panelClass: 'warning', verticalPosition: 'bottom', duration: 2500 });
                this.sessionService.isLoggedIn$.next(false);
                this.loading = false;
              }
            }
          },
            (error) => {
              console.log(error);
              this.loading = false;
              // if (error.error) {
              //   if (error.error.mensagem === 'Data truncation: Data too long for column \'pSenha\' at row 1') {
              //     this.snackBar.open('Incorrect username or password, please check and try again!', '', {
              //       panelClass: 'error', verticalPosition: 'bottom', duration: 5000
              //     });
              //     this.loading = false;
              //   } else {
              //     this.snackBar.open(error.error.mensagem, '', {
              //       panelClass: 'error', verticalPosition: 'bottom', duration: 5000
              //     });
              //     this.loading = false;
              //   }
              // } else {
              //   this.snackBar.open('Verify your data and try again!', '', {
              //     panelClass: 'error', verticalPosition: 'bottom', duration: 5000
              //   });
              //   this.loading = false;
              // }
              // console.log(error);
            });
      }
    }
  
    esqueciSenha() {
      this.divforgotPassword = true
    }

    onEsqueciSenha(form) {
        this.loading = true;
        this.recuperarSenhaService
          .recuperarSenha(form)
          .subscribe((data) => {
            this.snackBar.open('Password sent to your email', '', {
              panelClass: 'success', verticalPosition: 'bottom', duration: 2000
            });
            this.dialogRef.close()
            this.loading = false;
            this.router.navigate(['/'])
          },
            (error) => {          
              this.snackBar.open('User not found, verify your information', '', {
                panelClass: 'error', verticalPosition: 'bottom', duration: 2000
              });
              this.loading = false;
            })
      }

    public backToLogin() {
        this.divforgotPassword = false
    }
  
    solicitarCadasto() {
        this.closeModal()
        this.router.navigate(['/open-trade-account']);
    }
  
    // realizarCadastro(form, formDirective: FormGroupDirective) {
    //   if (!this.resaleCertificate && this.registerControls.salesTaxStatus.value === '2') {
    //     this.snackBar.open('Please put the Reseller Certificate', '', {
    //       panelClass: 'warning', verticalPosition: 'bottom', duration: 4000
    //     });
    //   } else if (this.registerForm.invalid) {
    //     this.snackBar.open('Please check your form and try again.', '', {
    //       panelClass: 'warning', verticalPosition: 'bottom', duration: 4000
    //     });
    //   } else {
    //     this.loading = true;
    //     form.urlResaleCertificate = this.resaleCertificate ?? '';
    //     form.A1_CONTATO = `${form.firstName} ${form.lastName}`;
    //     form.A1_NREDUZ = form.A1_NOME;
    //     form.dateTime = `Date: ${this.utilService.dataAtual()} Time: ${this.utilService.horaAtual()}`;
    //     delete form.firstName;
    //     delete form.lastName;
    //     const envio = [form];
    //     envio.map((item) => {
    //       Object.keys(item)
    //         .map((data) => {
    //           if (data === 'A1_HPAGE' || data === 'A1_EMAIL' || data === 'password' || data === 'urlResaleCertificate') {
    //             item[data] = item[data];
    //           } else {
    //             if (item[data]) {
    //               item[data] = this.utilService.removeAccents(item[data]);
    //             }
    //           }
    //         });
    //       return item;
    //     });
    //     console.log(envio[0], '<<<<<---- ENVIO');
    //     this.signInService
    //       .registerUser(JSON.stringify(envio[0]))
    //       .subscribe((data) => {
    //         this.snackBar.open(`${data.message}`, '', {
    //           panelClass: 'success', verticalPosition: 'bottom', duration: 2000
    //         });
    //         formDirective.resetForm();
    //         this.registerForm.reset();
    //         this.registerControls.salesTaxStatus.setValue('1');
    //         this.registerControls.minoriCasaRep.setValue('1');
    //         this.resaleCertificate = null;
    //         window.scrollTo(0, 0);
    //         this.loading = false;
    //       }, (err) => {
    //         console.log(err, 'ERROR');
    //         this.snackBar.open('Error, please check your form and try again.', '', {
    //           panelClass: 'error', verticalPosition: 'bottom', duration: 4000
    //         });
    //         this.loading = false;
    //       });
    //   }
    // }
  
    cleanFieldOther(ev) {
      if (ev.value !== 'Other') {
        this.registerControls.otherReferenceResource.setValue('');
      }
    }
  
    radioChange(event, key) {
      console.log(event, 'EVENT');
  
      if (event.value === '1') {
        this.registerControls[key].clearValidators();
        this.registerControls[key].setErrors(null);
      } else {
        this.registerControls[key].setValidators([Validators.compose([Validators.maxLength(30), Validators.required])]);
      }
    }
  
    // public checkSave(form, formDirective) {
    //   // const invalidForm = this.utilService.findInvalidControls(this.registerForm.controls);
    //   // if (invalidForm.length > 0) {
    //   //   invalidForm.map((data) => {
    //   //     return this.snackBar.open(`Error, please check your field: ${data} and try again.`, '', {
    //   //       panelClass: 'error', verticalPosition: 'bottom', duration: 3000
    //   //     });
    //   //   });
    //   // } else {
    //   this.realizarCadastro(form, formDirective);
    //   // }
    // }
  
    private listarPaises() {
      this.listaPaisesService.listaPaises()
        .subscribe((data) => {
          if (data.status) {
            const retorno = data.dados;
            this.countries = retorno.sort((a, b) => a.ordem - b.ordem);
          } else {
            this.countries = [];
          }
        })
    }
  
    private listarEstadosPorPais(codPais) {
      this.listaEstadosService.listaEstadosPorPais(codPais)
        .subscribe((data) => {
          console.log(data, 'data');
          if (data.status) {
            const retorno = data.dados;
            this.states = retorno.sort((a, b) => a.ordem - b.ordem);
          } else {
            this.states = [];
          }
        })
    }
  
    public selectContries(event) {
      if (event) {
        const filteredCountry = this.countries.filter((item) => item.codPais === event.value);
        this.registerControls.A1_EST.setValue('');
        this.registerControls.stateDescription.setValue('');
        this.registerControls.countryDescription.setValue(filteredCountry[0].nome);
        this.listarEstadosPorPais(event.value);
      }
    }
  
    public selectState(event) {
      if (event) {
        const filteredState = this.states.filter((item) => item.sigla === event.value);
        this.registerControls.stateDescription.setValue(filteredState[0].descricao);
      }
    }
  
    public viewVersion() {
      if (this.count < 5) {
        this.count++;
      } else {
        const message = this.version;
        this.snackBar.open(message,
          '', { panelClass: 'sucess', verticalPosition: 'bottom', duration: 2500 });
      }
    }
  
    closeModal(): void {
        this.dialogRef.close();
      }
  
  }
  