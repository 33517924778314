<app-loading [loading]="loading"></app-loading>
<div class="aligner">
    <div fxFlex="100" fxFlex.gt-sm="100">
        <div class="logo" fxShow="false" fxShow.gt-md>
            <a routerLink="/">
                <img
                    loading="lazy"
                    src="https://www.minoricasa.com/assets/images/logo/logo-bege-escuro.png"
                    alt="Minori Casa"
                    width="250"
                />
            </a>
        </div>
        <div fxShow="false" fxShow.lt-md>
            <div class="d-block">
                <div class="p-quadrado main-title">
                    <h1>Open Trade Account</h1>
                </div>
            </div>
        </div>

        <div *ngIf="!divSucess; else sucess">
            <div
                class="mt-3"
                fxLayout="row"
                fxLayoutAlign="space-around center"
            >
                <div style="margin: 0rem 20rem" class="no-shadow">
                    <form
                        class="auth"
                        [formGroup]="registerForm"
                        #formDirective="ngForm"
                        (keyup.enter)="
                            realizarCadastro(registerForm.value, formDirective)
                        "
                    >
                        <div class="main">
                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Company Name</mat-label>
                                <input
                                    matInput
                                    placeholder="Company Name"
                                    formControlName="A1_NOME"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_NOME.errors
                                            ?.required
                                    "
                                    >Company Name is required</mat-error
                                >
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_NOME.hasError(
                                            'maxlength'
                                        )
                                    "
                                    >The company name must contain a maximum of
                                    50 characters.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>First Name</mat-label>
                                <input
                                    matInput
                                    placeholder="First Name"
                                    formControlName="firstName"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerControls.firstName.errors
                                            ?.required
                                    "
                                    >First Name is required</mat-error
                                >
                                <mat-error
                                    *ngIf="
                                        registerControls.firstName.hasError(
                                            'maxlength'
                                        )
                                    "
                                    >The first name must contain a maximum of 25
                                    characters.</mat-error
                                >
                            </mat-form-field>

                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Last Name</mat-label>
                                <input
                                    matInput
                                    placeholder="Last Name"
                                    formControlName="lastName"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerControls.lastName.errors
                                            ?.required
                                    "
                                    >Last Name is required</mat-error
                                >
                                <mat-error
                                    *ngIf="
                                        registerControls.lastName.hasError(
                                            'maxlength'
                                        )
                                    "
                                    >The last name must contain a maximum of 25
                                    characters.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Business Type</mat-label>
                                <mat-select
                                    (selectionChange)="
                                        selectBusinessType($event)
                                    "
                                    formControlName="A1_CODSEG"
                                    required
                                >
                                    <mat-option
                                        *ngFor="let business of businessType"
                                        [value]="business.value"
                                        >{{ business.label }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_CODSEG.errors
                                            ?.required
                                    "
                                    >Business Type is required
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field
                                *ngIf="
                                    registerControls.A1_CODSEG.value !==
                                    '000006'
                                "
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Company Website</mat-label>
                                <input
                                    matInput
                                    placeholder="Company Website"
                                    formControlName="A1_HPAGE"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_HPAGE.errors
                                            ?.required
                                    "
                                    >Company Website is required
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_HPAGE.errors
                                            ?.pattern
                                    "
                                    >Please enter valid url
                                </mat-error>
                                <mat-hint class="mat-hint-size" align="end"
                                    >We'll use this to verify your company. No
                                    Website ?
                                    <a
                                        style="
                                            cursor: pointer;
                                            font-weight: 900;
                                        "
                                        (click)="openDialogContact()"
                                        >Contact Us</a
                                    >
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field
                                *ngIf="
                                    registerControls.A1_CODSEG.value ===
                                    '000006'
                                "
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Company Website</mat-label>
                                <input
                                    matInput
                                    placeholder="Company Website"
                                    formControlName="A1_HPAGE"
                                />
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_HPAGE.errors
                                            ?.pattern
                                    "
                                    >Please enter valid url
                                </mat-error>
                                <mat-hint class="mat-hint-size" align="end"
                                    >We'll use this to verify your company. No
                                    Website ?
                                    <a
                                        style="
                                            cursor: pointer;
                                            font-weight: 900;
                                        "
                                        (click)="openDialogContact()"
                                        >Contact Us</a
                                    >
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Country</mat-label>
                                <mat-select
                                    (selectionChange)="selectContries($event)"
                                    formControlName="A1_PAIS"
                                    required
                                >
                                    <mat-option
                                        *ngFor="let country of countries"
                                        [value]="country.codPais"
                                        >{{ country?.nome }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_PAIS.errors
                                            ?.required
                                    "
                                    >Country is required</mat-error
                                >
                            </mat-form-field>

                            <div *ngIf="states.length > 0">
                                <mat-form-field
                                    appearance="outline"
                                    class="w-100 mt-1"
                                >
                                    <mat-label>State</mat-label>
                                    <mat-select
                                        formControlName="A1_EST"
                                        (selectionChange)="selectState($event)"
                                        required
                                    >
                                        <mat-option
                                            *ngFor="let state of states"
                                            [value]="state.sigla"
                                            >{{ state?.descricao }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="
                                            registerControls.A1_EST.errors
                                                ?.required
                                        "
                                        >State is required</mat-error
                                    >
                                </mat-form-field>
                            </div>

                            <div
                                *ngIf="states.length <= 0"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                            >
                                <mat-form-field
                                    appearance="outline"
                                    class="w-45 mt-1"
                                >
                                    <mat-label>State </mat-label>
                                    <input
                                        matInput
                                        formControlName="stateDescription"
                                        required
                                    />
                                    <mat-error
                                        *ngIf="
                                            registerControls.stateDescription
                                                .errors?.required
                                        "
                                    >
                                        State is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field
                                    appearance="outline"
                                    class="w-45 mt-1"
                                >
                                    <mat-label>Abbreviation State </mat-label>
                                    <input
                                        matInput
                                        formControlName="A1_EST"
                                        required
                                        maxlength="2"
                                    />
                                    <mat-error
                                        *ngIf="
                                            registerControls.A1_EST.errors
                                                ?.required
                                        "
                                        >Abbreviation state is required
                                    </mat-error>
                                    <mat-error
                                        *ngIf="
                                            registerControls.A1_EST.hasError(
                                                'minlength'
                                            ) ||
                                            registerControls.A1_EST.hasError(
                                                'maxlength'
                                            )
                                        "
                                    >
                                        The abbreviation state must contain 2
                                        characters</mat-error
                                    >
                                </mat-form-field>
                            </div>

                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Street</mat-label>
                                <input
                                    matInput
                                    placeholder="Street"
                                    formControlName="A1_END"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_END.errors?.required
                                    "
                                    >Street is required</mat-error
                                >
                            </mat-form-field>

                            <div
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                            >
                                <mat-form-field
                                    appearance="outline"
                                    class="w-45 mt-1"
                                >
                                    <mat-label>Zip Code</mat-label>
                                    <input
                                        mask="09999-999"
                                        matInput
                                        formControlName="A1_CEP"
                                        autocomplete="nothing"
                                        required
                                        #numero
                                    />
                                    <mat-error
                                        *ngIf="
                                            registerControls.A1_CEP.errors
                                                ?.required
                                        "
                                        >Zip Code is required</mat-error
                                    >
                                    <mat-error
                                        *ngIf="
                                            registerControls.A1_CEP.hasError(
                                                'maxlength'
                                            )
                                        "
                                        >The Zip Code must contain a maximum of
                                        8 characters.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field
                                    appearance="outline"
                                    class="w-45 mt-1"
                                >
                                    <mat-label>City</mat-label>
                                    <input
                                        matInput
                                        formControlName="A1_MUN"
                                        required
                                    />
                                    <mat-error
                                        *ngIf="
                                            registerControls.A1_MUN.errors
                                                ?.required
                                        "
                                        >City is required</mat-error
                                    >
                                    <mat-error
                                        *ngIf="
                                            registerControls.A1_MUN.errors
                                                ?.pattern
                                        "
                                        >City accepts only letters.
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Email</mat-label>
                                <input
                                    type="email"
                                    matInput
                                    placeholder="Email"
                                    formControlName="A1_EMAIL"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_EMAIL.errors
                                            ?.required
                                    "
                                    >E-mail is required</mat-error
                                >
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_EMAIL.hasError(
                                            'invalidEmail'
                                        )
                                    "
                                    >Invalid E-mail</mat-error
                                >
                            </mat-form-field>

                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Phone Number</mat-label>
                                <input
                                    prefix="+1"
                                    mask=" 000-000-0000"
                                    matInput
                                    placeholder="Phone Number"
                                    formControlName="A1_TEL"
                                    autocomplete="nothing"
                                    required
                                    #numero
                                />
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_TEL.errors?.required
                                    "
                                    >Phone Number is required
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        registerControls.A1_TEL.hasError(
                                            'minlength'
                                        ) ||
                                        registerControls.A1_TEL.hasError(
                                            'maxlength'
                                        )
                                    "
                                >
                                    The Phone Number must contain 10 to 16
                                    characters</mat-error
                                >
                            </mat-form-field>

                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Create Password</mat-label>
                                <input
                                    matInput
                                    [type]="hide ? 'password' : 'text'"
                                    placeholder="Create Password"
                                    formControlName="password"
                                    required
                                    autocomplete="on"
                                />
                                <button
                                    mat-icon-button
                                    matSuffix
                                    (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide"
                                >
                                    <mat-icon>{{
                                        hide ? "visibility_off" : "visibility"
                                    }}</mat-icon>
                                </button>
                                <mat-error
                                    *ngIf="
                                        registerControls.password.errors
                                            ?.required
                                    "
                                    >Password is required</mat-error
                                >
                                <mat-error
                                    *ngIf="
                                        registerControls.password.hasError(
                                            'minlength'
                                        ) ||
                                        registerControls.password.hasError(
                                            'maxlength'
                                        )
                                    "
                                >
                                    The password must contain 5 to 12
                                    characters</mat-error
                                >
                            </mat-form-field>

                            <label
                                class="defalt-title-muted mt-1"
                                id="example-radio-group-label"
                                >Pick your sales tax status</label
                            >
                            <mat-radio-group
                                aria-labelledby="example-radio-group-label"
                                class="example-radio-group"
                                formControlName="salesTaxStatus"
                                required
                            >
                                <mat-radio-button
                                    class="example-radio-button"
                                    *ngFor="let sale of salesTaxStatus"
                                    [value]="sale.value"
                                >
                                    {{ sale.label }}
                                </mat-radio-button>
                                <mat-error
                                    *ngIf="
                                        registerControls.salesTaxStatus.errors
                                            ?.required
                                    "
                                    >Sales Tax Status is required
                                </mat-error>
                            </mat-radio-group>

                            <div
                                *ngIf="
                                    registerControls.salesTaxStatus.value ===
                                    '2'
                                "
                            >
                                <mat-form-field
                                    appearance="outline"
                                    class="w-100 mt-1"
                                >
                                    <mat-label
                                        >Resale Certificate Number</mat-label
                                    >
                                    <input
                                        matInput
                                        placeholder="Resale Certificate Number"
                                        formControlName="resaleCertificateNumber"
                                        required
                                    />
                                    <mat-error
                                        *ngIf="
                                            registerControls
                                                .resaleCertificateNumber.errors
                                                ?.required
                                        "
                                        >Resale Certificate Number is required
                                    </mat-error>
                                </mat-form-field>
                                <p class="defalt-title-muted">
                                    Upload Resale Certificate *
                                </p>
                                <!-- UPLOAD COMPONENT -->
                                <app-upload-file
                                    class="mt-1"
                                    (newItemEvent)="addItem($event)"
                                ></app-upload-file>
                            </div>

                            <div
                                *ngIf="
                                    registerControls.salesTaxStatus.value ===
                                    '3'
                                "
                            >
                                <mat-divider></mat-divider>
                                <p
                                    class="text-muted mt-2"
                                    style="font-weight: 900"
                                >
                                    The e-mail with the certificate should be
                                    sent to:
                                    <a
                                        href="mailto:contact@minoricasa.com"
                                        class="links-sobre link primary-color"
                                    >
                                        contact&#64;minoricasa.com</a
                                    >
                                </p>
                            </div>

                            <div style="padding-top: 3%">
                                <label
                                    class="defalt-title-muted mt-1"
                                    id="example-radio-group-label"
                                    >Have you been in contact with a Minori Casa
                                    rep ? *</label
                                >
                                <mat-radio-group
                                    aria-labelledby="example-radio-group-label"
                                    class="example-radio-group"
                                    formControlName="minoriCasaRep"
                                    required
                                >
                                    <mat-radio-button
                                        class="example-radio-button"
                                        *ngFor="let rep of minoriCasaRep"
                                        [value]="rep.value"
                                    >
                                        {{ rep.label }}
                                    </mat-radio-button>
                                    <mat-error
                                        *ngIf="
                                            registerControls.minoriCasaRep
                                                .errors?.required
                                        "
                                        >This field is required
                                    </mat-error>
                                </mat-radio-group>

                                <div
                                    *ngIf="
                                        registerControls.minoriCasaRep.value !==
                                        '1'
                                    "
                                >
                                    <mat-form-field
                                        appearance="outline"
                                        class="w-100 mt-1"
                                    >
                                        <mat-label>Rep</mat-label>
                                        <input
                                            matInput
                                            placeholder="What rep ?"
                                            formControlName="salesman"
                                        />
                                        <mat-error
                                            *ngIf="
                                                registerControls.salesman.errors
                                                    ?.required
                                            "
                                            >Rep is required</mat-error
                                        >
                                    </mat-form-field>
                                </div>
                            </div>

                            <p class="defalt-title-muted mt-1">
                                How did you hear about us ? *
                            </p>
                            <mat-form-field
                                appearance="outline"
                                class="w-100 mt-1"
                            >
                                <mat-label>Please choose an option</mat-label>
                                <mat-select
                                    formControlName="referenceResource"
                                    required
                                >
                                    <mat-option
                                        *ngFor="let val of referenceResource"
                                        [value]="val.value"
                                        >{{ val.label }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="
                                        registerControls.referenceResource
                                            .errors?.required
                                    "
                                    >This field is required
                                </mat-error>
                            </mat-form-field>

                            <div
                                *ngIf="
                                    registerControls.referenceResource.value ===
                                    'Other'
                                "
                            >
                                <mat-form-field
                                    appearance="outline"
                                    class="w-100 mt-1"
                                >
                                    <mat-label>Other</mat-label>
                                    <input
                                        matInput
                                        placeholder="Other"
                                        formControlName="otherReferenceResource"
                                        required
                                    />
                                    <mat-error
                                        *ngIf="
                                            registerControls
                                                .otherReferenceResource.errors
                                                ?.required
                                        "
                                        >This field is required
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <mat-checkbox
                                formControlName="consentData"
                                class="checkbox-agree"
                            >
                                By providing your information above, you consent
                                to Minori Casa adding you to our communication
                                database and contacting you using the provided
                                details. *
                            </mat-checkbox>

                            <p
                                class="defalt-title-muted mt-3"
                                style="margin: 0 14rem"
                            >
                                We aim to process your application within 2
                                working days. Need to expedite?
                                <a
                                    style="cursor: pointer; font-weight: 900"
                                    (click)="openDialogContact()"
                                    >Contact Us</a
                                >
                            </p>
                        </div>
                    </form>

                    <!-- <div class="divider mt-2 mb-2"></div> -->
                    <div>
                        <div
                            fxLayout="row wrap"
                            fxLayoutAlign="space-between"
                            class="theme-container max-width-inherit mt-4"
                        >
                            <div
                                fxFlex="100"
                                fxFlex.gt-sm="100"
                                fxFlex.sm="100"
                                class="text-center"
                            >
                                <button
                                    mat-fab
                                    color="primary"
                                    [disabled]="!consent"
                                    class="mat-elevation-z6 cadastrar"
                                    (click)="
                                        realizarCadastro(
                                            registerForm.value,
                                            formDirective
                                        )
                                    "
                                >
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #sucess>
            <div
                class="mt-3"
                fxLayout="row"
                fxLayoutAlign="space-around center"
            >
                <div style="margin: 0rem 20rem" class="no-shadow">
                    <div class="mt-2 mb-2"></div>
                    <div
                        fxLayout="row wrap"
                        fxLayoutAlign="space-between"
                        class="theme-container max-width-inherit"
                    >
                        <div
                            fxFlex="100"
                            fxFlex.gt-sm="100"
                            fxFlex.sm="100"
                            class="text-center"
                        >
                            <h2>Thank you for subscribing!</h2>
                        </div>
                        <div class="mt-2 mb-2"></div>
                    </div>
                    <div
                        fxLayout="row wrap"
                        fxLayoutAlign="space-between"
                        class="theme-container max-width-inherit"
                    >
                        <div
                            fxFlex="100"
                            fxFlex.gt-sm="100"
                            fxFlex.sm="100"
                            class="align-justify"
                        >
                            <h3 class="fw-500">
                                Soon you will receive an e-mail confirming you
                                subscription.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
