import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from 'process';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignInService {

  private endpoint = `/cadastro-clientes-b2b`;

  constructor(private http: HttpClient) { }

  public registerUser(json): Observable<any> {
    return this.http.post(`${environment.url.api}/public/v1${this.endpoint}`, json, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    }) as Observable<any>;
  }

  public uploadResaleCertificate(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.url.api}/public/v1/uploadCertificadoB2B`, formData) as Observable<any>;
  }
}
