<mat-toolbar class="top-navbar mat-elevation-z2" fxLayoutAlign="center center">
    <ul class="theme-container w-100">
        <li *ngFor="let m of menu" class="border-right">
            <a mat-button [routerLink]="['/products', m.label, m.route, m.exibeAgrupamentoCores]" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">
                {{m.label}}
                <!-- <strong class="subtituloMenu">{{m.subTitulo}}</strong> -->
            </a>
        </li>
    </ul>
</mat-toolbar>

<!-- <mat-toolbar class="top-navbar mat-elevation-z2" fxLayoutAlign="center center">

    <div *ngFor="let m of menu; let i=index">
        <a mat-button [matMenuTriggerFor]="animals">{{m.label}}<mat-icon *ngIf="m.subMenu.length > 0" class="caret">arrow_drop_down</mat-icon></a>
        <mat-menu #animals="matMenu" [overlapTrigger]="false" class="app-dropdown">
            <a *ngFor="let s of m.subMenu" mat-menu-item [routerLink]="['/products', s.breadcrumb, s.codigo, s.exibeAgrupamentoCores]" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{s.descricao}}</a>  -->
<!-- <a *ngFor="let s of m.subMenu" mat-menu-item [routerLink]="['/products', s.breadcrumb, s.codigo]" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{s.descricao}}</a>  -->
<!-- </mat-menu>
    </div>

</mat-toolbar> -->