<app-loading [loading]="loading"></app-loading>
<form [formGroup] = "uploadForm">
<div
  *ngIf="!resaleCertificate"
  (click)="fileInput.click()"
  appDragDrop
  (onFileDropped)="uploadFile($event)"
>
  <input
    hidden
    type="file"
    name="profile"
    #fileInput
    (change)="uploadFile($event.target.files[0])"
  />
  <div class="upload-image">
    <div class="upload-text">
      <label for="file"
        ><strong>Choose a file</strong><span> or drag it here</span>.</label
      >
    </div>
  </div>
</div>
</form>

<div class="files-list" *ngIf="haveFile">
  <p>File {{ resaleCertificate?.name }} uploaded with success!</p>
  <button
    matTooltip="Delete file ?"
    class="delete-file"
    (click)="deleteAttachment(i)"
  >
    <img src="../../assets/icons/Recycle_Bin_Full.png" />
  </button>
</div>
