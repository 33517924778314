import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface CarrinhoEnvio {
  itens: Array<CarrinhoEnvioItem>;
  usuario: string;
  idSessao: string;
  codCliente: string;
}

export interface CarrinhoEnvioItem {
  id: number;
  lotes: Array<CarrinhoEnvioLotes>;
  largura: number;
  redondo: number;
  idProduto: string;
  precoUnit: number;
  tipoBorda: number;
  codProduto: string;
  quantidade: string;
  comprimento: number;
}

export interface CarrinhoEnvioLotes {
  lote: string;
  subLote: string;
  quantidade: string;
}

export interface CarrinhoRetorno {
  itens: Array<CarrinhoRetornoItens>;
  idCarrinho?: number;
  data?: string;
  cupom?: string;
  dataExpira?: string;
  tempo?: number;
  idSessao?: string;
  usuario?: string;
  idPedido?: number;
  codCliente?: string;
  temCarrinho:boolean
}

export interface CarrinhoRetornoItens {
  largura: number;
  url: string;
  descricao: string;
  codProduto: string;
  percIcmsST: number;
  preco: number;
  estoque: number;
  idProduto: number;
  foraDeLinha: number;
  lotes: Array<any>;
  comprimento: number;
  vendaSobMedidaM2: number;
  id: number;
  percIPI: number;
  valorIcmsST: number;
  totaITem: number;
  valorIPI: number;
  quantidade: number;
  totaIBruto: number;
  qtdLimitePedidoAcimaEstoque: number;
}

export interface ExcluirItemRetorno {
  mensagem: string;
}

export interface ResumoCarrinho {
  total: number;
  cupom: string;
  totalSemIpi: number;
  quantidade: number;
}

export interface PedidoEnvio {
  codCliente: string;
  codRepresentante: string;
  condPagamento: string;
  frete: string;
  nomeTransportadora?: string;
  dddTransportadora?: string;
  foneTransportadora?: string;
  observacaoPedido: string;
  numeroPedidoCompra?: string;
  observacaoShipTo?: string;
  observacaoPaypal?: string;
}

export interface AplicarCupomEnvio {
  codCliente: string;
  cupom: string;
  aplicar: number;
}

export interface AplicarCupomRetorno {
  mensagem: string;
}

export interface PedidoRetorno {
  totalPedido?: number;
  totalItens?: number;
  numeroPedidoCompra: string;
  itens: [
    {
      observacao: string,
      valorLiquido: number,
      largura: number,
      valorDesconto: number,
      totalItem: number,
      valorBruto: number,
      chaveErp: string,
      valorTabela: number,
      bonificado: number,
      valorICMSST: number,
      numPedCompra: string,
      percentualIpi: number,
      url: string,
      descricao: string,
      codProduto: string,
      valorUnitario: number,
      corteRedondo: number,
      percDesconto: number,
      comprimento: number,
      chaveErpPedido: string,
      qtdVenda: number,
      valorIPI: number,
      numItemPedCompra: string
    }
  ];
  formaPagto: {
    codigo: string,
    chaveErp: string,
    descricao: string
  };
  numeroOrcamento: string;
  idPedidoGoSales: number;
  idPedidoAPP: number;
  tipoFrete: {
    codigo: string,
    chaveErp: string,
    descricao: string
  };
  chaveErp: string;
  tabelaPreco: {
    codigo: string,
    chaveErp: string,
    descricao: string
  };
  codCliente: string;
  transportadora: {};
  codRepresentante2: string;
  condPagto: {
    codigo: string,
    descricao: string
  };
  codRepresentante: string;
  observacoes: [
    {
      tipoObservacao: {
        codigo: string,
        chaveErp: string,
        descricao: string
      },
      observacao: string,
      chaveErp: string,
      codTipoObs: string;
    }
  ];
  cupom: string;
  cancelado: number;
  representante: {};
  numeroPedido: string;
  emissao: string;
  status: {
    codStatus: string,
    descricao: string
  };
  opened?: boolean;
}

export interface CarrinhosAbandonados {
  lista: Array<CarrinhosAbandonadosItens>;
}

export interface CarrinhosAbandonadosItens {
  itens: Array<CarrinhosAbandonadosProdutos>;
  idCarrinho: number;
  data: string;
  dataExpira: string;
  tempo: number;
  idSessao: string;
  usuario: string;
  idPedido: number;
  codCliente: string;
  totalValor?: number; // propriedade só existe no front, não obrigatória, apenas para auxilio na soma
  totalItens?: number; // propriedade só existe no front, não obrigatória, apenas para auxilio na soma
  opened?: boolean;
}

export interface CarrinhosAbandonadosProdutos {
  largura: number;
  url: string;
  descricao: string;
  codProduto: string;
  percIcmsST: number;
  preco: number;
  estoque: number;
  idProduto: number;
  foraDeLinha: number;
  comprimento: number;
  id: number;
  percIPI: number;
  valorIcmsST: number;
  totaITem: number;
  valorIPI: number;
  quantidade: number;
  totaIBruto: number;
  qtdLimitePedidoAcimaEstoque: number;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public emitCart: EventEmitter<any> = new EventEmitter<any>();
  public emitPedido: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public snackBar: MatSnackBar,
    private http: HttpClient
  ) { }

  public insertCarrinho(carrinho: CarrinhoEnvio): Observable<CarrinhoRetorno> {
    const retorno = this.http.post(`${environment.url.api}/private/v1/drctCarrinhoInserir`, carrinho) as Observable<CarrinhoRetorno>;
    this.emitCart.emit();
    return retorno;
  }

  public excluirItem(codCliente: string, idItem: number, idProduto: number, codProduto: string): Observable<ExcluirItemRetorno> {
    const retorno = this.http.delete(`${environment.url.api}/private/v1/drctCarrinhoItemExcluir/${codCliente}/${idItem}/${idProduto}/${codProduto}`) as Observable<ExcluirItemRetorno>;
    this.emitCart.emit('excluir');
    return retorno;
  }

  public carrinhosAbandonados(codCliente: string): Observable<CarrinhosAbandonados> {
    const retorno = this.http.get(`${environment.url.api}/private/v1/drctListarCarrinhosAbandonados?codCliente=${codCliente}`) as Observable<CarrinhosAbandonados>;
    this.emitCart.emit();
    return retorno;
  }

  public resumoCarrinho(codCliente: string): Observable<ResumoCarrinho> {
    return this.http.get(`${environment.url.api}/private/v1/drctCarrinhoResumo?codCliente=${codCliente}`) as Observable<ResumoCarrinho>;
  }

  public obterCarrinho(codCliente: string): Observable<CarrinhoRetorno> {
    return this.http.get(`${environment.url.api}/private/v1/drctCarrinhoObter?codCliente=${codCliente}`) as Observable<CarrinhoRetorno>;
  }

  public pedidoInserir(pedido: PedidoEnvio): Observable<PedidoRetorno> {
    const retorno = this.http.post(`${environment.url.api}/private/v1/drctCarrinhoPedidoInserir`, pedido) as Observable<PedidoRetorno>;
    this.emitPedido.emit();
    return retorno;
  }

  public aplicarCupom(cupom: AplicarCupomEnvio): Observable<AplicarCupomRetorno> {
    const retorno = this.http.post(`${environment.url.api}/private/v1/drctAplicarCupom`, cupom) as Observable<AplicarCupomRetorno>;
    this.emitCart.emit();
    return retorno;
  }

  public recuperarCarrinho(idCarrinho: number, codCliente: string) {
    return this.http.put(`${environment.url.api}/private/v1/drctCarrinhoRecuperar/${idCarrinho}/${codCliente}`, '');
  }

}
