<div class="background-modal" fxLayout="row">
    <div fxFlex="65">
        <div class="p-0 main" fxShow="false" fxShow.gt-sm>
            <div class="aligner">
                <div fxLayout="row" class="nome_produto" style="align-items: baseline;">
                    <img fxFlex="6" class="sob-medida" src="https://maioricasa.s3.amazonaws.com/site-images/icons/sob-medida-ruler.png"
                        alt="regua-sob-medida"
                    >
                    <h3 fxFlex="94" class="title">{{data?.descricao | titlecase}}</h3>
                </div>
                <hr class="line" />

                <div class="mat-table cart-table">

                    <div style="width: 100%;">

                        <h2 class="section-title">Avisos Importantes</h2>

                        <ul style="margin-top: 1.5rem !important;">
                            <li>
                                <span> Prazo de faturamento: 20 dias úteis após liberação do pedido junto ao crédito;
                                </span>
                            </li>
                            <li>
                                <span> No caso de tapetes com desenho, não garantimos alinhamento de RAPPORT; </span>
                            </li>
                            <li>
                                <span> Não aceitaremos devoluções de tapetes sob medida por erro de projeto; </span>
                            </li>
                            <li>
                                <span> Tapetes sob medida são adicionados ao carrinho individualmente; </span>
                            </li>
                            <li>
                                <span> A produção de tapetes sob medida passam pela análise e aprovação de nossa equipe.
                                </span>
                            </li>
                            <li>
                                <span> Na opção por borda virada, será adicionado automaticamente 5cm em cada lado que
                                    tiver com esse acabamento aplicado. </span>
                            </li>
                        </ul>



                    </div>

                </div>
                <hr class="line" />
            </div>
        </div>
        <div class="botoes">
            <div fxFlex="60">
                <a mat-button class="flex-row-button btn" (click)="fecharModal(false)">
                    <mat-icon class="mat-icon-md">navigate_before</mat-icon>
                    <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs class="top-cart">
                        <span style="font-size: 16px">Voltar as Compras</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div fxFlex="36.8" class="values" style="border-radius: 15px 0px 0px 15px;">
        <form [formGroup]="sobMedidaForm">
        <div fxLayout="column" style="height: 100%">
            <div fxLayout="column" class="mat-row white-border">
                <div fxLayout="row wrap">
                    <div fxFlex="89">
                        <h3 class="title-bloco ml-3 mr-3">Acabamento</h3>
                    </div>
                    <div fxFlex="11">
                        <button class="stock-icon-container" mat-icon-button (click)="fecharModal(true)">
                            <img class="close" src="https://minori-casa.s3.amazonaws.com/site-images/icons/close-brown.png"
                            alt="close">
                        </button>
                    </div>
                </div>
                <mat-form-field appearance="outline" fxFlex="100" class="ml-3 mr-3">
                    <mat-label>Formato</mat-label>
                    <mat-select formControlName="formato" class="custom-select-value">
                        <mat-option *ngFor="let formato of selectFormato"
                            [value]="formato.value">{{formato.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="100" class="ml-3 mr-3">
                    <mat-label>Borda</mat-label>
                    <mat-select formControlName="borda" class="custom-select-value">
                        <mat-option *ngFor="let borda of selectBorda" [value]="borda.value">{{borda.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <hr fxFlex="100" class="line-bloco ml-3 mr-3" />

                <div fxLayout="column" class="mat-row white-border">
                    <h3 class="title-bloco ml-3 mr-3">Tamanho</h3>
                    <mat-form-field appearance="outline" fxFlex="100" class="ml-3 mr-3">
                        <mat-label>Largura</mat-label>
                        <input style="color: #fff;" id="largura"  (focus)="resetaInput('largura', $event)"min="0" step="0.05" formControlName="largura" type="number" matInput placeholder="Ex.: 2,00">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="ml-3 mr-3">
                        <mat-label>Comprimento</mat-label>
                        <input  style="color: #fff;"  id="comprimento" (focus)="resetaInput('comprimento', $event)" min="0" step="0.05" formControlName="comprimento" type="number" matInput placeholder="Ex.: 2,00">
                    </mat-form-field>
                    <div class="ml-3 mr-3" fxLayout="column">
                        <mat-hint class="text-hint">Medidas em metro. - Ex.: 2,45 = 2m e 45cm</mat-hint>
                        <mat-hint class="text-hint">Largura Máxima: 4m | Altura Máxima: 10m</mat-hint>
                    </div>
                </div>
    
                <hr fxFlex="100" class="line-bloco ml-3 mr-3" />
            </div>


            <div fxLayout="row" fxLayoutAlign="space-between" class="mat-row btnsCart">
                <div fxLayout="row" fxFlex="50">
                    <h3 class="title-bloco mt-2 ml-3 mr-3">Total:</h3>
                    <span class="title-valor">{{  valorTotal  | currency }}</span>
                </div>
                <div fxFlex="50">
                    <button class="align-button" (click)="addToCart()">
                        <img class="sob-medida"
                            src="https://maioricasa.s3.amazonaws.com/site-images/icons/add-cart-white.png" alt="close">
                        <div class="flex-item">
                            <p style="font-size:12px; color: #F7F7F7;letter-spacing: 0.65px;">
                                Adicionar<br>
                                ao carrinho
                            </p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        </form>
    </div>
</div>