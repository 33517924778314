<div *ngIf="products" class="swiper-container" [swiper]="config">
    <h3 class="titleSessao">{{products.sessao}}</h3>
    <div class="swiper-wrapper h-100">
        <div *ngFor="let produto of products.produtos" class="swiper-slide">
            <mat-card class="product-item text-center">
                <a [routerLink]="['/products/', produto.codProduto, codTabelaPreco]" fxLayoutAlign="center center" class="image-link text-truncate mb-1">
                    <img src="{{produto.url}}" alt="{{produto.descricao}}" />
                </a>
                <a [routerLink]="['/products/', produto.codProduto, codTabelaPreco]" class="title text-truncate">
                    <p>{{produto.descricao}}</p>
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="prices">
                        <div fxLayout="column" fxLayoutAlign="center center" class="w-100">
                            <p class="new-price">$ {{formatterValue(produto.preco)}}</p>
                        </div>
                    </div>

                    <div class="divider"></div>
                    <div class="icons">
                        <button color="primary" mat-raised-button>
                            See more
                        </button>
                        <!-- <app-controls [product]="product" (onOpenProductDialog)="openProductDialog(product)"></app-controls> -->
                    </div>
                </a>
            </mat-card>
        </div>
    </div>
    <button mat-mini-fab class="swiper-button-prev swipe-arrow">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>