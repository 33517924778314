import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-depoimentos',
  templateUrl: './depoimentos.component.html',
  styleUrls: ['./depoimentos.component.scss']
})
export class DepoimentosComponent implements OnInit {
  public configDepoimentos: SwiperConfigInterface = {};

  constructor() { }

  ngAfterViewInit() {
    this.configDepoimentos = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 40,
      keyboard: false,
      navigation: {
        nextEl: '.swiperNextDepoimento',
        prevEl: '.swiperPrevDepoimento'
      },
      pagination: false,
      grabCursor: false,
      loop: false,
      preloadImages: true,
      lazy: false,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 3
        },
        1500: {
          slidesPerView: 3
        }
      }
    };
  }

  ngOnInit() { }

}
