import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { JsonGenerico } from 'src/app/interfaces/json-generico.model';

export interface Busca {
  lista: Array<BuscaItem>;
}

export interface BuscaItem {
  urlGrade: string;
  codigo: string;
  imagens: [
    {
      tipoImagem: number;
      url: string;
      sequenciaExibicao?: number;
  }
  ];
  codigoGrade: string;
  ordem: number;
  codigoCor: string;
  descricao: string;
  descricaoCor: string;
  descricaoGrade: string;
  achouImg?: boolean;
}
// codigo: string,
// urlImagemGrade: string,
// descricao: string,

@Injectable({
  providedIn: 'root'
})
export class BuscaService {

  constructor(private http: HttpClient) { }

  public buscaPorProdutos(termo?: string): Observable<JsonGenerico<Array<BuscaItem>>> {
    const body = {
      servico: 'drctBuscaProdutos',
      termo
    };
    return this.http.post(`${environment.url.api}/public/v1/jsonGenerico`, body) as Observable<JsonGenerico<Array<BuscaItem>>>;
  }
}
