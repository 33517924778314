import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { emailValidator } from 'src/app/theme/utils/app-validators';
import { ObterIdentificador, ObterIdentificadorService } from 'src/app/services/sign-in/obter-identificador.service';
import { NewsletterService } from 'src/app/services/newsletter/newsletter.service';
import { RedesSociaisFooter } from 'src/app/interfaces/redes-sociais-footer.model';
import { UtilService } from 'src/app/services/util/util.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalContactComponent } from 'src/app/shared/modal-contact/modal-contact.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit, AfterViewChecked {
  isExpanded: boolean = false;

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  @ViewChild("content", { static: false }) content: ElementRef;

  public lat = 40.678178;
  public lng = -73.944158;
  public zoom = 12;
  public disabled = true;

  newsForm: FormGroup;

  codCliente: string;

  showNews = false;

  public provedor: string;
  public sac: string;
  public site: string;
  public marca = "Maiori Casa";

  public redesBase: RedesSociaisFooter = {
    // youtube: "https://www.youtube.com/@minoricasa",
    facebook: "https://www.facebook.com/minoricasa/",
    instagram: "https://www.instagram.com/minoricasa/",
    whatsapp: "",
    pinterest: "https://pinterest.com/Minoricasa/",
  };

  public whatsapp = "+1 305 927 3050";
  public email: string;
  public identificador: number;
  public isMobile = false;
  contentHeight: string = "0px";
  isOpen: boolean = false;

  constructor(
    private fb: FormBuilder,
    private newsletterService: NewsletterService,
    private snackBar: MatSnackBar,
    public router: Router,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isMobile = window.innerWidth > 731 ? false : true;

    this.newsForm = this.fb.group({
      nome: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, emailValidator])],
      telefone: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(11),
          Validators.minLength(11),
        ]),
      ],
    });

    this.newsForm.valueChanges.subscribe((data) => {
      this.disabled = this.newsForm.invalid;
    });

    this.router.events.subscribe((event) => {
      const urlLocation = this.location.path();
      urlLocation === "/cart" || urlLocation === "/checkout"
        ? (this.showNews = false)
        : (this.showNews = true);
    });
  }

  toggleContent() {
    this.isOpen = !this.isOpen;
  }

  ngAfterViewInit() {
    this.contentHeight = "0px";
  }

  ngAfterViewChecked() {}

  get controls() {
    return this.newsForm.controls;
  }
  cadastrarUser() {
    const ddd = this.controls.telefone.value.slice(0, 2);
    const tel = this.controls.telefone.value.slice(2, 11);
    const obj = {
      nome: this.controls.nome.value,
      email: this.controls.email.value,
      ddd,
      telefone: tel,
      codCliente: this.codCliente,
    };
    console.log(obj);
    this.newsletterService.receberNews(obj).subscribe(
      (data) => {
        this.snackBar.open("Successful registration!", "", {
          panelClass: "success",
          verticalPosition: "bottom",
          duration: 3000,
        });
        this.newsForm.reset();
        window.scrollTo(0, 0);
      },
      (error: HttpErrorResponse) => {
        this.snackBar.open("Registration error!", error.error.mensagem, {
          panelClass: "error",
          verticalPosition: "bottom",
          duration: 3000,
        });
        console.error(error.error.mensagem);
      }
    );
  }

  public openDialogContact() {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return (
          navigator.userAgent.match(/IEMobile/i) ||
          navigator.userAgent.match(/WPDesktop/i)
        );
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      },
    };
    if (isMobile.any() === null) {
      this.dialog.open(ModalContactComponent, {
        data: {},
        height: "650px",
        width: "650px",
        maxWidth: "50vw",
        maxHeight: "100vh",
        panelClass: "dialog-container-arredondado",
      });
    } else {
        this.dialog.open(ModalContactComponent, {
          data: {},
          maxWidth: "100vw",
          maxHeight: "100vh",
          height: "100%",
          width: "100%",
          panelClass: "dialog-container-arredondado",
        });
      }
  }


  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }
}
