import { Component, OnInit, Input } from '@angular/core';

import { Menu } from 'src/app/interfaces/menu.model';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/session/session.service';
import { interval } from 'rxjs';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menu: Array<any> = [];

  public codTabelaPreco: string;

  constructor(
    private homeService: HomeService,
    private sessionService: SessionService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.sessionService.codTabelaPreco$.subscribe(data => this.codTabelaPreco = data);
    this.getMenu();
  }

  getMenu() {
    this.homeService.homeSessoes(this.codTabelaPreco)
      .subscribe((data) => {
        data.dados.menus.sort((a, b) => a.ordem - b.ordem);
        data.dados.menus.map((item) => {
          item.subMenu.sort((a, b) => a.ordem - b.ordem);
          item.subMenu.unshift({ codigo: item.codigo, descricao: 'Todos', breadcrumb: 'Todos Produtos', exibeAgrupamentoCores: item.exibeAgrupamentoCores, ordem: 0 });
          // item.subMenu.unshift({ codigo: item.codigo, descricao: 'Todos', breadcrumb: 'Todos Produtos' });
          item.subMenu.map((sub, i) => {
            sub.breadcrumb = `${item.descricaoMenu} - ${sub.descricao}`;
            sub.descricao = this.utilService.convertFirstUpperCase(sub.descricao);
          });
          this.menu.push({ label: item.descricaoMenu, route: item.codigo, subTitulo: item.subTitulo, ordem: item.ordem, subMenu: item.subMenu, exibeAgrupamentoCores: item.exibeAgrupamentoCores });
          // this.menu.push({ label: item.descricaoMenu, route: item.codigo, subTitulo: item.subTitulo, ordem: item.ordem, subMenu: item.subMenu });
        })
      })
  }

}
