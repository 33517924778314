<div class="swiper-container fonte-centaur" [swiper]="config">
    <div class="swiper-wrapper h-100">
        <div *ngFor="let sub of itens" class="swiper-slide mt-2">
            <mat-card class="product-item text-center" fxLayout="row wrap">
                <div fxLayoutAlign="center center" class="image-link text-truncate mb-1" fxFlex.lt-md="100" fxFlex.gt-sm="50">
                    <img [src]="sub.url" [alt]="sub.descricao" />
                </div>
                <div fxFlex.xs="100" fxFlex.gt-xs="50" class="div-externa">
                    <div class="div-interna">
                        <p class="text-justify text-midia">{{sub.descricao}}</p>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <button mat-mini-fab class="swiper-button-prev swipe-arrow">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>