import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MidiaCarroselItem } from 'src/app/interfaces/midia-eventos.model';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-midia-carousel',
  templateUrl: './midia-carousel.component.html',
  styleUrls: ['./midia-carousel.component.scss']
})
export class MidiaCarouselComponent implements OnInit, AfterViewInit {

  @Input('itens') itens: Array<MidiaCarroselItem>;

  public config: SwiperConfigInterface = {};

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: true,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 1
        },
        960: {
          slidesPerView: 1
        },
        1280: {
          slidesPerView: 1
        },
        1500: {
          slidesPerView: 1
        }
      }
    };
  }

}
