import { Component, OnInit, Input } from '@angular/core';

import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {
  @Input('slides') slides: Array<any> = [];

  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  constructor(private router: Router) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: false,
      navigation: {
        nextEl: '.swiperNextBanner',
        prevEl: '.swiperPrevBanner'
      },
      pagination: this.pagination,
      grabCursor: false,
      loop: false,
      preloadImages: true,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "fade"
    }
  }

  public goTo(url: string) {
    if (url.indexOf('http') != -1 || url.indexOf('www') != -1) {
      window.open(url, 'blank');
    } else {
      this.router.navigate([url]);
    }
  }

}