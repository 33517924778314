import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'protractor';
import { SessionService } from 'src/app/services/session/session.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  options: AnimationOptions = {    
    path: '/assets/lottie/loading-minoricasa.json'  
  };  

  @Input() loading: boolean = false;

  public identificador;

  constCss = {
    visibility: 'hidden',
    width: '1000px',
    height: '1000px',
    backgroundColor: 'rgba(255, 254, 254, 0.5)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    display: 'block'
  }
  

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService
      .identificador$
      .subscribe((data) => {
        this.identificador = data?.identificador;
      },
        (error: HttpErrorResponse) => {
          console.log(error);
        });
  }

    // This is the component function that binds to the animationCreated event from the package  
    onAnimate(animationItem: AnimationItem): void {    
      console.log(animationItem);  
    }

}
