<h3 class="title" fxLayout="row">Depoimentos</h3>

<button mat-mini-fab class="swiper-button-next swipe-arrow swiperPrevDepoimento">
    <mat-icon>keyboard_arrow_left</mat-icon>
</button>

<div class="swiper-container" id="containerDepoimento" [swiper]="configDepoimentos">
    <div class="swiper-wrapper h-100">
        <div class="item-depoimento swiper-slide">
            <div class="ratings">
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
            </div>
            <span class="texto-depoimento">"Maravilhosa iniciativa de buscarem o que há de melhor no mercado para oferecermos qualidade aos nossos clientes"</span>
            <span class="autor-depoimento mt-1 text-right">Cristiane, Loja @novoprojeto</span>
        </div>
        <div class="item-depoimento swiper-slide">
            <div class="ratings">
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
            </div>
            <span class="texto-depoimento">"Os tapetes Asiatex tem um design moderno e preço acessível que com certeza vai girar nas lojas"</span>
            <span class="autor-depoimento mt-1 text-right">Cinara, Loja @artmodernadecorin</span>
        </div>
        <div class="item-depoimento swiper-slide">
            <div class="ratings">
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
            </div>
            <span class="texto-depoimento">"Gosto dos produtos Asiatex porque eles associam qualidade, bom gosto e preços acessíveis"</span>
            <span class="autor-depoimento mt-1 text-right">Amanda Monteiro - Loja Nome Decorart</span>
        </div>
        <div class="item-depoimento swiper-slide">
            <div class="ratings">
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
                <mat-icon _ngcontent-oky-c179="" role="img" class="mat-icon notranslate mat-icon-xs material-icons mat-icon-no-color ng-star-inserted" aria-hidden="true">star</mat-icon>
            </div>
            <span class="texto-depoimento">"Além de super vendáveis, os produtos Asiatex estão em alta como referência no mercado!"</span>
            <span class="autor-depoimento mt-1 text-right">Alex Bortolin – Loja Móveis Santana</span>
        </div>
    </div>
    
</div>

<button mat-mini-fab class="swiper-button-next swipe-arrow swiperNextDepoimento">
    <mat-icon>keyboard_arrow_right</mat-icon>
</button>