import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AppModule } from './app.module';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: 'Home' } },
            {
                path: 'products',
                loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),
                data: { breadcrumb: 'All the products' }
            },
            {
              path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'My Account'}
            },
            {
                path: 'checkout',
                loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule),
                data: { breadcrumb: 'Checkout' }
            },
            {
                path: 'about',
                loadChildren: () => import('./pages/sobre-nos/sobre-nos.module').then(m => m.SobreNosModule),
                data: { breadcrumb: 'MINORI CASA' }
            },
            // {
            //     path: 'where-buy',
            //     loadChildren: () => import('./pages/onde-comprar/onde-comprar.module').then(m => m.OndeComprarModule),
            //     data: { breadcrumb: 'Where to buy' }
            // },
            {
                path: 'events',
                loadChildren: () => import('./pages/na-midia/na-midia.module').then(m => m.NaMidiaModule),
                data: { breadcrumb: 'Events' }
            },
            {
                path: 'contact',
                loadChildren: () => import('./pages/contato/contato.module').then(m => m.ContatoModule),
                data: { breadcrumb: 'Contact' }
            },
            {
                path: 'terms-conditions',
                loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
                data: { breadcrumb: 'TERMS & CONDITIONS' }
            },
            {
                path: 'samples-program',
                loadChildren: () => import('./pages/sample-program/sample-program.module').then(m => m.SampleProgramModule),
                data: { breadcrumb: 'Sample Program' }
            },
            {
                path: 'media',
                loadChildren: () => import('./pages/media/media.module').then(m => m.MediaModule),
                data: { breadcrumb: 'TERMS & CONDITIONS' }
            },
            {
                path: 'rug-care',
                loadChildren: () => import('./pages/rug-care/rug-care.module').then(m => m.RugCareModule),
                data: { breadcrumb: 'RUG CARE' }
            },
            {
                path: 'open-trade-account',
                loadChildren: () => import('./pages/open-trade-account/open-trade-account.module').then(m => m.OpenTradeAccountModule),
                data: { breadcrumb: 'RUG CARE' }
            },
            {
                path: 'cart',
                loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule),
                data: { breadcrumb: 'Cart' }
            },
            {
                path: 'gift-samples',
                loadChildren: () => import('./pages/gift-samples/gift-samples.module').then(m => m.GiftSamplesModule)
            },
            {
                path: 'verificar-pixel',
                loadChildren: () => import('./pages/verificar-pixel/verificar-pixel.module').then(m => m.VerificarPixelModule)
            }
        ]
    },
    { path: '**', component: NotFoundComponent },
];

// export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

export const routing: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
});
