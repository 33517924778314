<app-loading [loading]="loading"></app-loading>
<div fxFlex="100">
    <div *ngIf="!divforgotPassword; else forgotPassword" class="aligner">
        <div fxFlex="100" fxLayout="column" fxFlex.gt-sm="100" class="ml-6" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <div FxFlex="100" fxLayout.gt-sm="row wrap" fxLayout.gt-xs="row wrap">
                <div fxFlex="90" fxLayoutAlign="space-around center">
                    <img class="logo" src="https://www.minoricasa.com/assets/images/logo/logo-bege-escuro.png" loading="eager" alt="Minori Casa" [title]="version" (click)="viewVersion()" />
                </div>
                <div fxFlex="10" fxFlex.xs="14%">
                    <button  class="stock-icon-container" mat-icon-button (click)="closeModal()">
                        <img class="close" src="https://minori-casa.s3.amazonaws.com/site-images/icons/close.png" alt="close">
                    </button>
                </div>
            </div>
            <div FxFlex="30" fxLayout.gt-sm="row wrap" fxLayout.gt-xs="row wrap">
                <form class="auth" [formGroup]="loginForm" (keyup.enter)="onLoginFormSubmit(loginForm.value)">
                    <mat-form-field appearance="outline" class="w-100 mt-2 form">
                        <mat-label class="form">Email</mat-label>
                        <input class="form" type="email" matInput formControlName="email" required />
                        <mat-error *ngIf="loginForm.controls.email.errors?.required">E-mail is required</mat-error>
                        <mat-error *ngIf="loginForm.controls.email.hasError('invalidEmail')">Invalid E-mail</mat-error>
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="w-100 mt-1">
                        <mat-label class="form">Password</mat-label>
                        <input class="form" matInput [type]="hide ? 'password' : 'text'" formControlName="senha" />
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-error *ngIf="loginForm.controls.senha.errors?.required">Password is required</mat-error>
                        <mat-error *ngIf="
                  loginForm.controls.senha.hasError('minlength') ||
                  loginForm.controls.senha.hasError('maxlength')
                ">
                            The password must contain 5 to 12 characters</mat-error>
                    </mat-form-field>
    
                    <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                        <div class="text-center w-100">
                            <button matTooltip="Sign In" mat-fab color="primary" class="mat-elevation-z6 w-100 btn-signIn" (click)="onLoginFormSubmit(loginForm.value)"> Sign In
                            </button>
                        </div>
                    </div>
    
                    <div class="mt-2 mb-2"></div>
    
                    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="theme-container">
                        <button mat-fab (click)="esqueciSenha()" class="no-shadow w-100 btn-secundary">
                            Forgot your password?
                        </button>
                    </div>
    
                    <div class="divider mt-2 mb-2"></div>
    
                    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="theme-container">
                        <button mat-fab (click)="solicitarCadasto()" class="no-shadow w-100 btn-secundary">
                            Don't have an account? Sign up now!
                        </button>
                    </div>
    
                </form>
            </div>
        </div>
    </div>
    <ng-template #forgotPassword>
        <div class="aligner">
            <div fxFlex="100" fxFlex.gt-sm="100" class="ml-6" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <div style="margin: 0 auto; text-align: center;">
                    <img class="logo" src="https://www.minoricasa.com/assets/images/logo/logo-bege-escuro.png" alt="Logo da marca" width="250">
                </div>
                <form class="conteudo" [formGroup]="esqueciSenhaForm">
                    <h1 class="text-muted text-center">Forgot Password</h1>
                    <h3 class="text-muted text-center">To recover your password, enter your registered e-mail address.</h3>
                    <mat-form-field appearance="outline" class="w-100 mt-2">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="Email" formControlName="email" required>
                        <mat-error *ngIf="esqueciSenhaForm.controls.email.errors?.required">E-mail is required</mat-error>
                        <mat-error *ngIf="esqueciSenhaForm.controls.email.hasError('invalidEmail')">Invalid E-mail</mat-error>
                    </mat-form-field>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="theme-container">
                        <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.sm="100" class="text-center">
                            <button matTooltip="Recovery password" [disabled]="disabled" mat-fab (click)="onEsqueciSenha(esqueciSenhaForm.value)" class="no-shadow w-100 btnsEsqueci">
                                <mat-icon>check</mat-icon> Recovery password
                            </button>
                        </div>               
                        <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.sm="100" class="text-center" ngClass.lt-sm="mt-1">
                            <button matTooltip="Back to login" mat-fab (click)="backToLogin()" class="no-shadow w-100 btnsEsqueci">
                                <mat-icon>keyboard_return</mat-icon> Back to login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</div>
