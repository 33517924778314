<!-- START CONTACT US - WEB-->
<div *ngIf="!isMobile; else mobile">
    <div class="contact">
        <div fxLayout="row wrap" fxLayout.gt-sm="row wrap">
            <div fxFlex="60" class="row">
                <h1 class="text-left mt-2">Contact us</h1>
                <h4 class="text-left fonte-centaur">Visit our showroom!</h4>
                <h4 class="text-left fonte-centaur">Clear your doubts</h4>
            </div>
            <div fxFlex="40" fxLayout="column">
                <div
                    FxFlex="10"
                    fxLayout.gt-sm="row wrap"
                    fxLayout.gt-xs="column"
                >
                    <button
                        class="stock-icon-container"
                        mat-icon-button
                        (click)="closeModal()"
                    >
                        <img
                            class="close"
                            src="https://minori-casa.s3.amazonaws.com/site-images/icons/close.png"
                            alt="close"
                        />
                    </button>
                </div>

                <div FxFlex="80" id="media" class="column">
                    <p class="mt-2 primary-color">
                        <span class="d-inline-block mr-2">
                            <a
                                [href]="redesBase?.whatsapp"
                                target="_blank"
                                class="primary-color d-flex-center"
                            >
                                <i
                                    class="fa fa-whatsapp float"
                                    alt="icon-whatsapp"
                                ></i>
                            </a>
                        </span>
                        <span class="d-inline-block mr-1">
                            <a
                                [href]="redesBase?.pinterest"
                                target="_blank"
                                class="primary-color d-flex-center"
                            >
                                <i
                                    class="fa fa-pinterest float-icon"
                                    style="font-size: 35px; color: #6a4e3e"
                                ></i>
                            </a>
                        </span>
                        <span class="d-inline-block mr-1">
                            <a
                                [href]="redesBase?.instagram"
                                target="_blank"
                                class="primary-color d-flex-center"
                            >
                                <img
                                    src="../../assets/icons/footer/icon-awesome-instagram.png"
                                    alt="icon-instagram"
                                    class="icons-p"
                                    srcset=""
                                />
                            </a>
                        </span>
                        <span class="d-inline-block mr-1">
                            <a
                                [href]="redesBase?.facebook"
                                target="_blank"
                                class="primary-color d-flex-center"
                            >
                                <img
                                    src="../../assets/icons/footer/icon-awesome-facebook.png"
                                    alt="icon-facebook"
                                    class="icons-p"
                                    srcset=""
                                />
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <form [formGroup]="registerForm">
            <div
                class="banners-container mt-3"
                fxLayout="row wrap"
                fxLayout.gt-sm="row wrap"
                fxLayout.xs="column"
                fxLayout.sm="column"
            >
                <div
                    fxLayout="row wrap"
                    fxLayout.gt-sm="column wrap"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxFlex="55"
                >
                    <div fxLayout="row wrap" autocomplete="nothing">
                        <div
                            class="example-form-field"
                            fxFlex="100"
                            fxFlex.gt-sm="100"
                            ngClass.sm="mt-1"
                            ngClass.xs="mt-1"
                        >
                            <mat-form-field
                                appearance="outline"
                                class="w-100 input"
                            >
                                <mat-label class="fonte-centaur"
                                    >Full Name</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="Full Name"
                                    formControlName="nome"
                                    autocomplete="nothing2"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.nome.errors
                                            ?.required
                                    "
                                    >The field is required
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.nome.errors
                                            ?.minlength
                                    "
                                    >The Full Name must contain at least 3
                                    digits</mat-error
                                >
                            </mat-form-field>
                        </div>

                        <div
                            fxFlex="100"
                            fxFlex.gt-sm="100"
                            ngClass.sm="mt-1"
                            ngClass.xs="mt-1"
                        >
                            <mat-form-field
                                appearance="outline"
                                class="w-100 input"
                            >
                                <mat-label class="fonte-centaur"
                                    >E-mail</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="E-mail"
                                    formControlName="email"
                                    autocomplete="nothing2"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.email.errors
                                            ?.required
                                    "
                                    >The field is required.
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.email.hasError(
                                            'invalidEmail'
                                        )
                                    "
                                    >Invalid e-mail address
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div
                            fxFlex="100"
                            fxFlex.gt-sm="100"
                            ngClass.sm="mt-1"
                            ngClass.xs="mt-1"
                        >
                            <mat-form-field
                                appearance="outline"
                                class="w-100 input"
                            >
                                <mat-label class="fonte-centaur"
                                    >Phone</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="Phone"
                                    formControlName="telefone"
                                    autocomplete="nothing"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.telefone.errors
                                            ?.required
                                    "
                                    >The field is required.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- <div fxFlex="100" fxFlex.gt-sm="100" ngClass.sm="mt-1" ngClass.xs="mt-1">
                            <mat-form-field appearance="outline" class="w-100 input">
                                <mat-label class="fonte-centaur">Subject</mat-label>
                                <input matInput placeholder="Subject" formControlName="assunto"
                                    autocomplete="nothing2" required="" />
                                    <mat-error *ngIf="registerForm.controls.assunto.errors?.required">The field is required.
                                    </mat-error>
                            </mat-form-field>
                        </div> -->

                        <div
                            fxFlex="100"
                            fxFlex.gt-sm="100"
                            ngClass.sm="mt-1"
                            ngClass.xs="mt-1"
                        >
                            <mat-form-field
                                appearance="outline"
                                class="w-100 input"
                            >
                                <mat-label class="fonte-centaur"
                                    >Company Name</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="Company Name"
                                    formControlName="companyName"
                                    autocomplete="nothing2"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div
                    fxLayout="row wrap"
                    fxLayout.gt-sm="column wrap"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxFlex="45"
                >
                    <div fxFlex="100" fxFlex.gt-sm="100" class="px-1 mr-4">
                        <mat-form-field
                            id="message"
                            appearance="outline"
                            class="w-30"
                        >
                            <mat-label class="fonte-centaur">Message</mat-label>
                            <textarea
                                class="mat-form-field-wrapper"
                                matInput
                                placeholder="Message"
                                required
                                formControlName="mensagem"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="20"
                                cdkAutosizeMaxRows="20"
                            ></textarea>
                            <mat-error
                                *ngIf="
                                    registerForm.controls.mensagem.errors
                                        ?.required
                                "
                                >The field is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>

        <!-- <div id="buttonDefault" mat-dialog-actions align="end">
            <a mat-dialog-close class="btn">CLOSE</a>
        </div> -->

        <div
            class="banners-container"
            fxLayoutAlign="space-between center"
            fxLayout="row wrap"
            fxLayout.xs="column"
            fxLayout.sm="column"
            fxLayoutAlign.xs="space-evenly stretch"
            fxLayoutAlign.sm="space-evenly stretch"
        >
            <div
                fxLayout="column"
                fxFlex="55"
                ngClass.sm="mt-1"
                ngClass.xs="mt-1"
            >
                <p fxFlex="row" id="botoes">
                    Select:
                    <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="idTipoCliente">
                            <mat-radio-button class="example-radio-button" *ngFor="let item of clientType"
                                [value]="item.value">
                                {{item?.label}}
                            </mat-radio-button>
                        </mat-radio-group> -->
                </p>
                <div
                    fxLayout="row"
                    id="botoes"
                    fxLayout.xs="row"
                    fxLayout.sm="row"
                >
                    <button
                        [ngClass]="{
                            'cor-de-fundo': item.selected,
                            select: !item.selected
                        }"
                        *ngFor="let item of clientType"
                        class="select"
                        (click)="selectItem(item)"
                    >
                        {{ item?.label }}
                    </button>
                </div>
            </div>

            <div fxFlex="44">
                <button
                    class="mat-elevation-z6 submit"
                    (click)="verifyFormAndSave(registerForm)"
                >
                    Submit
                </button>
            </div>
        </div>
    </div>

    <div class="theme-container mr-4">
        <hr class="line" />
    </div>

    <div class="banners-container footer mt-1 mb-2" fxLayCout="row wrap">
        <div fxFlex="5" class="img" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <i
                class="fas fa-map-marked-alt"
                style="font-size: 40px; color: #6a4e3e"
            ></i>
        </div>
        <div fxFlex="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <h4 class="titulo">Our Office/Showroom</h4>
            <h5>10307 NW 62nd St Doral</h5>
            <h5>FL 33178 USA</h5>
        </div>
        <div
            class="fonte-centaur"
            fxFlex="45"
            class="px-1"
            ngClass.sm="mt-1"
            ngClass.xs="mt-1"
        >
            <h4>Office: + 1 786-452-8980</h4>
            <h4>Sales: + 1 305-927-3050</h4>
            <h4>contact@minoricasa.com</h4>
        </div>
    </div>
</div>

<!-- START CONTACT US - MOBILE-->
<ng-template #mobile>
    <div fxLayout="row wrap" style="padding: 0rem 0rem 0rem 2rem">
        <div fxFlex="85" fxFlex.sm="90" fxFlex.xs="85" class="row">
            <h1 class="text-left mt-3">Contact us</h1>
            <h4 class="text-left fonte-centaur">Visit our showroom!</h4>
            <h4 class="text-left fonte-centaur">Clear your doubts</h4>
        </div>
        <div
            FxFlex="15"
            fxFlex.sm="15"
            fxFlex.xs="15"
            fxLayout.gt-sm="row wrap"
            fxLayout.gt-xs="column"
        >
            <button
                class="stock-icon-container"
                mat-icon-button
                (click)="closeModal()"
            >
                <img
                    class="close"
                    src="https://minori-casa.s3.amazonaws.com/site-images/icons/close.png"
                    alt="close"
                />
            </button>
        </div>
        <!-- <div FxFlex="35" class="close" fxLayout.gt-sm="row wrap" fxLayout.gt-xs="column">
                    <button mat-icon-button (click)="closeModal()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div> -->
        <div fxFlex="100" fxLayout="column">
            <div FxFlex="80" class="media">
                <p class="mt-2 primary-color">
                    <span class="d-inline-block mr-2">
                        <a
                            [href]="redesBase?.whatsapp"
                            target="_blank"
                            class="primary-color d-flex-center"
                        >
                            <i
                                class="fa fa-whatsapp float"
                                alt="icon-whatsapp"
                            ></i>
                        </a>
                    </span>
                    <span class="d-inline-block mr-1">
                        <a
                            [href]="redesBase?.pinterest"
                            target="_blank"
                            class="primary-color d-flex-center"
                        >
                            <i
                                class="fa fa-pinterest icons-p"
                                style="
                                    font-size: 35px;
                                    color: #6a4e3e;
                                    margin-bottom: 2px;
                                "
                            ></i>
                        </a>
                    </span>
                    <span class="d-inline-block mr-1">
                        <a
                            [href]="redesBase?.instagram"
                            target="_blank"
                            class="primary-color d-flex-center"
                        >
                            <img
                                src="../../assets/icons/footer/icon-awesome-instagram.png"
                                alt="icon-instagram"
                                class="icons-p"
                                srcset=""
                            />
                        </a>
                    </span>
                    <span class="d-inline-block mr-1">
                        <a
                            [href]="redesBase?.facebook"
                            target="_blank"
                            class="primary-color d-flex-center"
                        >
                            <img
                                src="../../assets/icons/footer/icon-awesome-facebook.png"
                                alt="icon-facebook"
                                class="icons-p"
                                srcset=""
                            />
                        </a>
                    </span>
                </p>
            </div>
        </div>
    </div>

    <div id="contact">
        <form [formGroup]="registerForm">
            <div
                class="mt-3"
                fxLayout="row wrap"
                fxLayout.gt-sm="row wrap"
                fxLayout.xs="column"
                fxLayout.sm="column"
            >
                <div
                    fxLayout="row wrap"
                    fxLayout.gt-sm="column wrap"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxFlex="55"
                >
                    <div fxLayout="row wrap" autocomplete="nothing">
                        <div
                            class="example-form-field"
                            fxFlex="100"
                            fxFlex.gt-sm="100"
                        >
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label class="fonte-centaur"
                                    >Full Name</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="Full Name"
                                    formControlName="nome"
                                    autocomplete="nothing2"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.nome.errors
                                            ?.required
                                    "
                                    >The field is required
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.nome.errors
                                            ?.minlength
                                    "
                                    >The Full Name must contain at least 3
                                    digits
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxFlex="100" fxFlex.gt-sm="100">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label class="fonte-centaur"
                                    >E-mail</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="E-mail"
                                    formControlName="email"
                                    autocomplete="nothing2"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.email.errors
                                            ?.required
                                    "
                                    >The field is required
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.email.hasError(
                                            'invalidEmail'
                                        )
                                    "
                                    >Please use a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxFlex="100" fxFlex.gt-sm="100">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label class="fonte-centaur"
                                    >Phone</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="Phone"
                                    formControlName="telefone"
                                    autocomplete="nothing"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.telefone.errors
                                            ?.required
                                    "
                                    >The field is required
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- <div fxFlex="100" fxFlex.gt-sm="100">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label class="fonte-centaur"
                                    >Subject</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="Subject"
                                    formControlName="assunto"
                                    autocomplete="nothing2"
                                    required=""
                                />
                                <mat-error
                                    *ngIf="
                                        registerForm.controls.assunto.errors
                                            ?.required
                                    "
                                    >The field is required.
                                </mat-error>
                            </mat-form-field>
                        </div> -->

                        <div fxFlex="100" fxFlex.gt-sm="100">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label class="fonte-centaur"
                                    >Company Name</mat-label
                                >
                                <input
                                    matInput
                                    placeholder="Company Name"
                                    formControlName="companyName"
                                    autocomplete="nothing2"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div
                    fxLayout="row wrap"
                    fxLayout.gt-sm="column wrap"
                    fxLayout.xs="column"
                    fxLayout.sm="column"
                    fxFlex="45"
                >
                    <div fxFlex="100" fxFlex.gt-sm="100">
                        <mat-form-field
                            id="message"
                            appearance="outline"
                            class="w-30"
                        >
                            <mat-label class="fonte-centaur">Message</mat-label>
                            <textarea
                                matInput
                                placeholder="Message"
                                required
                                formControlName="mensagem"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="15"
                                cdkAutosizeMaxRows="15"
                            ></textarea>
                            <mat-error
                                *ngIf="
                                    registerForm.controls.mensagem.errors
                                        ?.required
                                "
                                >The field is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!-- modalContactComponent -->
            <div
                class="banners-container"
                fxLayoutAlign="space-between center"
                fxLayout="row wrap"
                fxLayout.xs="column"
                fxLayout.sm="column"
                fxLayoutAlign.xs="space-evenly stretch"
                fxLayoutAlign.sm="space-evenly stretch"
            >
                <div
                    fxLayout="column"
                    fxFlex="40"
                    ngClass.sm="mt-1"
                    ngClass.xs="mt-1"
                >
                    <p fxFlex="row">
                        Select:
                        <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                formControlName="idTipoCliente">
                                <mat-radio-button class="example-radio-button" *ngFor="let item of clientType"
                                    [value]="item.value">
                                    {{item?.label}}
                                </mat-radio-button>
                            </mat-radio-group> -->
                    </p>
                    <div
                        fxLayout="column"
                        fxLayout.xs="column"
                        fxLayout.sm="row"
                        fxLayoutAlign="space-between center"
                    >
                        <button
                            [ngClass]="{
                                'cor-de-fundo': item.selected,
                                select: !item.selected
                            }"
                            *ngFor="let item of clientType"
                            class="select"
                            (click)="selectItem(item)"
                        >
                            {{ item?.label }}
                        </button>
                    </div>
                </div>

                <div fxFlex="44" class="">
                    <button
                        class="mat-elevation-z6 submit"
                        (click)="verifyFormAndSave(registerForm)"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="theme-container mt-4 mr-4">
        <hr class="line" />
    </div>
    <div
        class="banners-container-footer mt-2 mb-3"
        fxLayout.sm="row"
        fxLayout.xs="column"
    >
        <div fxFlex="10" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <i
                class="fas fa-map-marked-alt"
                style="font-size: 40px; color: #6a4e3e"
            ></i>
        </div>
        <div fxFlex="60" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <h4 class="titulo">Our Office/Showroom</h4>
            <h5>10307 NW 62nd St Doral</h5>
            <h5>FL 33178 USA</h5>
        </div>
        <div
            class="fonte-centaur"
            fxFlex="40"
            class="px-1"
            ngClass.sm="mt-1"
            ngClass.xs="mt-1"
        >
            <h4>Office: + 1 786-452-8980</h4>
            <h4>Sales: + 1 305-927-3050</h4>
            <h4>contact@minoricasa.com</h4>
        </div>
    </div>

    <!-- <div id="buttonDefault" mat-dialog-actions align="end">
            <a mat-dialog-close class="btn">CLOSE</a>
        </div> -->
</ng-template>
