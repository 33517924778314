<div *ngIf="!typeMobile" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" class="text-muted">
    <div *ngIf="product?.estoque > 0 || product?.qtdLimitePedidoAcimaEstoque > 0" [ngClass]="{
        'm-1-auto' : !originProduct,
        'm-1-auto' : originProduct
    }">
        <bdi>
            <button mat-icon-button matTooltip="Remove" (click)="decrement()">
                <mat-icon >remove</mat-icon>
            </button>
            <input style="background-color: initial;" [(ngModel)]="count" type="number" class="input-car" (keyup.enter)="changeModelDebounce($event)" (ngModelChange)="changeModelDebounce($event)">
            <button mat-icon-button matTooltip="Add" (click)="increment()">
                <mat-icon >add</mat-icon>
            </button>
        </bdi>
    </div>

</div>

<div *ngIf="typeMobile" class="text-muted align-mobile">
    <div *ngIf="product?.estoque > 0 || product?.qtdLimitePedidoAcimaEstoque > 0" [ngClass]="{
        'm-1-auto custom' : !originProduct,
        'm-1-auto' : originProduct
    }">
        <bdi>
            <button mat-icon-button matTooltip="Remove" (click)="decrement()">
                <mat-icon >remove</mat-icon>
            </button>
            <input style="background-color: initial;" [(ngModel)]="count" type="number" class="input-car" (keyup.enter)="changeModelDebounce($event)" (ngModelChange)="changeModelDebounce($event)">
            <button mat-icon-button matTooltip="Add" (click)="increment()">
                <mat-icon >add</mat-icon>
            </button>
        </bdi>
    </div>

</div>