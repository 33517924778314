import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ListaEstados {
  estado: string;
  sigla: string;
  descricao?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ListaEstadosService {

  constructor(
    private http: HttpClient
  ) { }

  public listaEstados(): Array<ListaEstados> {
    return [
      { estado: 'Alabama', sigla: 'AL' },
      { estado: 'Alaska', sigla: 'AK' },
      { estado: 'Arizona', sigla: 'AZ' },
      { estado: 'Arkansas', sigla: 'AR' },
      { estado: 'California', sigla: 'CA' },
      { estado: 'Colorado', sigla: 'CO' },
      { estado: 'Connecticut', sigla: 'CT' },
      { estado: 'Delaware', sigla: 'DE' },
      { estado: 'Florida', sigla: 'FL' },
      { estado: 'Georgia', sigla: 'GA' },
      { estado: 'Hawaii', sigla: 'HI' },
      { estado: 'Idaho', sigla: 'ID' },
      { estado: 'Illinois', sigla: 'IL' },
      { estado: 'Indiana', sigla: 'IN' },
      { estado: 'Iowa', sigla: 'IA' },
      { estado: 'Kansas', sigla: 'KS' },
      { estado: 'Kentucky', sigla: 'KY' },
      { estado: 'Louisiana', sigla: 'LA' },
      { estado: 'Maine', sigla: 'ME' },
      { estado: 'Maryland', sigla: 'MD' },
      { estado: 'Massachusetts', sigla: 'MA' },
      { estado: 'Michigan', sigla: 'MI' },
      { estado: 'Minnesota', sigla: 'MN' },
      { estado: 'Mississippi', sigla: 'MS' },
      { estado: 'Missouri', sigla: 'MO' },
      { estado: 'Montana', sigla: 'MT' },
      { estado: 'Nebraska', sigla: 'NE' },
      { estado: 'Nevada', sigla: 'NV' },
      { estado: 'New Hampshire', sigla: 'NH' },
      { estado: 'New Jersey', sigla: 'NJ' },
      { estado: 'New Mexico', sigla: 'NM' },
      { estado: 'New York', sigla: 'NY' },
      { estado: 'North Carolina', sigla: 'NC' },
      { estado: 'North Dakota', sigla: 'ND' },
      { estado: 'Ohio', sigla: 'OH' },
      { estado: 'Oklahoma', sigla: 'OK' },
      { estado: 'Oregon', sigla: 'OR' },
      { estado: 'Pennsylvania', sigla: 'PA' },
      { estado: 'Rhode Island', sigla: 'RI' },
      { estado: 'South Carolina', sigla: 'SC' },
      { estado: 'South Dakota', sigla: 'SD' },
      { estado: 'Tennessee', sigla: 'TN' },
      { estado: 'Texas', sigla: 'TX' },
      { estado: 'Utah', sigla: 'UT' },
      { estado: 'Vermont', sigla: 'VT' },
      { estado: 'Virginia', sigla: 'VA' },
      { estado: 'Washington', sigla: 'WA' },
      { estado: 'West Virginia', sigla: 'WV' },
      { estado: 'Wisconsin', sigla: 'WI' },
      { estado: 'Wyoming', sigla: 'WY' }
    ];
  }

  listaEstadosPorPais(codPais) {
    return this.http.get(`${environment.url.api}/public/v1/directEstadosPorPais?codPais=${codPais}`) as Observable<any>
  }

}
