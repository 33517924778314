<app-loading [loading]="loading"></app-loading>

<div class="top-menu" matTooltip="My Account">
    <div *ngIf="log; else logoff">   
        <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
            <img class="account" src="{{ userIcon }}"
                alt="account-user">
        </a>
    
        <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
            <span>
                <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                    <p>{{userInformation?.fantasia}} <br>
                    <small style="font-size: 12px;">{{userInformation?.email}}</small>
                        <!-- <small>emilio_v <span *ngIf="settings.rtl">&rlm;</span>(152
                <mat-icon class="mat-icon-xs">star</mat-icon>)</small> -->
                    </p>
                </div>
                <div class="divider itens">
                    <a mat-menu-item routerLink="/account" class="icons">
                        <img class="cart" src="https://maioricasa.s3.amazonaws.com/site-images/icons/icon-settings.png">
                        <span>Account Infomation</span>
                    </a>
                    <a mat-menu-item fxHide="false" routerLink="/account/orders"  class="icons">
                        <img class="cart" src="https://maioricasa.s3.amazonaws.com/site-images/icons/icon-orders.png">
                        <span>My Orders</span>
                    </a>
                    <a mat-menu-item fxHide="false" routerLink="/account/my-carts" class="icons">
                        <img class="cart" src="https://minori-casa.s3.amazonaws.com/site-images/icons/cart.png">
                        <span style="margin-left: 10%;" >My Carts</span>
                    </a>
                    <a *ngFor="let cat of catalogos" mat-menu-item fxHide="false" href="{{cat.href}}" target="_blank">
                        <mat-icon class="mat-icon-sm">{{cat.icon}}</mat-icon>
                        <span>Catálogo - {{cat.name}}</span>
                    </a>
                </div>
                <div class="divider">
                    <button mat-menu-item (click)="logout()" class="icons"> 
                        <img class="cart" src="https://maioricasa.s3.amazonaws.com/site-images/icons/icon-exit.png">
                        <span style="margin-left: 10%;">Sign Out</span> 
                    </button>
                </div>
            </span>
        </mat-menu>
    
    </div>
    <ng-template #logoff>
        <a mat-button (click)="openDialogSignIn()">
            <img style="width: 1.7rem;" src="{{ userIcon }}"  alt="account-user">
        </a>
    </ng-template>

</div>