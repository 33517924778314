import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ListaGenerica } from 'src/app/interfaces/lista-generica.model';
import { JsonGenerico } from 'src/app/interfaces/json-generico.model';
import { TabelaTamanhoProduto } from 'src/app/interfaces/tabela-tamanhos-produto.model';


export interface GradePorProduto {
  codigoGrade: string;
  composicao: string;
  descricaoDetalhada: string;
  descricaoGrade: string;
  gramatura: string;
  martindale: string;
  origemProduto: string;
  produtos: Array<ProdutosItem>;
  tecnica: string;
  tipoUso: string;
  urlImagemTransicao: string;
  urlPerformance: string;
  urlThumbnail: string;
  urlVideo: string;
}
export interface ProdutosLista {
  produtos: Array<ProdutosItem>,
  relacionados: Array<ProdutosRelacionados>
}

export interface ProdutosItem {
  preco: number;
  imagens: Array<Imagens>;
  largura: number;
  codigoCor: string;
  codigoCategoria: string;
  descricao: string;
  idProduto: number;
  codProduto: string;
  comprimento: number;
  foraDeLinha: number;
  descricaoCor: string;
  codigoTamanho: string;
  percentualIpi: number;
  estoqueProduto: EstoqueProduto;
  quantidade?: number,
  fatorConversao?: number,
  estoque: number;
  previsaoEntrega: Array<ProdutosPrevisaoEntrega>;
  descricaoTamanho: string;
  publicadoProduto: number;
  vendaSobMedidaM2: number;
  qtdLimitePedidoAcimaEstoque: number;
  disponibilidadeProduto: string;
  descricaoFichaTecnica: string;
  categorias: Array<CategoriasProduto>;
  isSelected?: boolean;
  sequenciaExibicaoTipo1: number;
  possui3dWarehouse: number;
  url3dWarehouse: string;
  descricaoCorWeb: string;
  urlThumbnail: string;
  urlVideo: string;
  SEQUENCE?:number;
}

export interface ProdutosPrevisaoEntrega {
  dataPrevisao: string,
  quantidade: number
}

export interface ProdutosRelacionados {
  // url: string,
  codigo: string,
  imagens: any,
  codigoCor: string,
  descricao: string,
  codigoGrade: string,
  descricaoCor: string,
  descricaoGrade: string
}

export interface EstoqueProduto {
  chaveErp: string,
  codigoProduto: string,
  lotes: Array<any>,
  quantidade: number
}

export interface Imagens {
  tipoImagem: number,
  urlImagem: string
  sequenciaExibicao: number;
  ordem?: number;
}

export interface Cores {
  descricaoCor: string,
  codigoCor: string,
  urlImagem: string

}

export interface ProdutosUnico {
  produtos: Array<ProdutosItem>,
  relacionados: Array<ProdutosRelacionados>
}

export interface RetornoDados {
  comprimento: number,
  largura: number,
  codigoCor: string,
  descricao: string,
  codProduto: string,
  preco: number,
  descricaoCor: string,
  estoqueProduto: EstoqueProduto,
  codigoTamanho: string,
  percentualIpi: number,
  idProduto: number,
  foraDeLinha: number,
  previsaoEntrega: Array<any>,
  publicadoProduto: number,
  vendaSobMedidaM2: number,
  qtdLimitePedidoAcimaEstoque: number,
  descricaoTamanho: string,
  categorias: Array<CategoriasProduto>
}

export interface CategoriasProduto {
  categoriaSuperior: string,
  codigoCategoria: string,
  descricao: string
}

export interface MedidasMaxMin {
  maxcomprimento: number,
  mincomprimento: number,
  minlargura: number,
  maxlargura: number
}

export interface ModalEstoqueProduto {
  isTapete: boolean;
  lotes: Array<any>;
  tapetes: Array<TabelaTamanhoProduto>;
  titulo: string;
}

export interface LotesProduto {
    lote: string;
    subLote: string;
    chaveErp: string;
    codigoProduto: string;
    quantidade: number;
    isSelecionado?: boolean;
  }
@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  public loadingPage: EventEmitter<boolean> = new EventEmitter();
  public emitTipoProduto: EventEmitter<any> = new EventEmitter<any>();


  constructor(private http: HttpClient) { }

  private relativeLink: string = 'public/v1/jsonGenerico';

  public gradePorProduto(codProduto?: string, codTabelaPreco?: string): Observable<ListaGenerica<Array<GradePorProduto>>> {
    let body = {
      servico: "drctGradePorProduto",
      codTabelaPreco: codTabelaPreco,
      codProduto: codProduto
    }
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, body) as Observable<ListaGenerica<Array<GradePorProduto>>>;
  }

  public gradePorGrade(codigoGrade?: string, codTabelaPreco?: string): Observable<JsonGenerico<Array<GradePorProduto>>> {
    let body = {
      servico: "drctGradePorGrade",
      codTabelaPreco: codTabelaPreco,
      codigoGrade: codigoGrade
    }
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, body) as Observable<JsonGenerico<Array<GradePorProduto>>>;
  }

  public getProduto(codigoProduto?: string, codTabelaPreco?: string): Observable<JsonGenerico<ProdutosLista>> {
    let body = {
      servico: "drctProduto",
      codTabelaPreco: codTabelaPreco,
      codProduto: codigoProduto
    }
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, body) as Observable<JsonGenerico<ProdutosLista>>;
  }

  public getPrevisaoPorGradeCor(codigoGrade?: string, codigoCor?: string): Observable<JsonGenerico<Array<any>>> {
    let body = {
      servico: "drctPrevisaoPorGradeCor",
      codigoGrade: codigoGrade,
      codigoCor: codigoCor
    }
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, body) as Observable<JsonGenerico<any>>;
  }

  public validaSobMedida(sobmedida) {
    return this.http.post(`${environment.url.api}/public/v1/drctValidaSobMedida`, sobmedida);
    //as Observable<CarrinhoRetorno>
  }

  public fnEventEmitter(loading: boolean): void {
    this.loadingPage.emit(loading);
  }

  public obterFichaTecnica() {
    return of([
      {
        "codigoGrade": "0549",
        "descricaoGrade": "SURATH",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0474",
        "descricaoGrade": "MARUT",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0540",
        "descricaoGrade": "MARUT DROP",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0536",
        "descricaoGrade": "STRUTTURA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0555",
        "descricaoGrade": "ZAHI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0526",
        "descricaoGrade": "MERAKI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0532",
        "descricaoGrade": "MERAKI 02",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0485",
        "descricaoGrade": "AQUILA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0538",
        "descricaoGrade": "PUJA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0537",
        "descricaoGrade": "RANI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0465",
        "descricaoGrade": "AMARA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0535",
        "descricaoGrade": "WABI SABI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0539",
        "descricaoGrade": "CHAKRA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0473",
        "descricaoGrade": "RUDRA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0546",
        "descricaoGrade": "VAIL",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0547",
        "descricaoGrade": "CAPSTONE",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0548",
        "descricaoGrade": "ST BARTS",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0583",
        "descricaoGrade": "VAN DYKE",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0585",
        "descricaoGrade": "MANTRA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0586",
        "descricaoGrade": "MASALA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0587",
        "descricaoGrade": "NAMASKAR",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0588",
        "descricaoGrade": "VARCHA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0589",
        "descricaoGrade": "PAANI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0590",
        "descricaoGrade": "KAVI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0591",
        "descricaoGrade": "ARYA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0592",
        "descricaoGrade": "RAVA",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0593",
        "descricaoGrade": "MEHNDI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0594",
        "descricaoGrade": "HOLI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0595",
        "descricaoGrade": "RUSHNI",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0596",
        "descricaoGrade": "AZMAN",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0597",
        "descricaoGrade": "GREAT TATCH",
        "possuiFichaTecnica": true
      },
      {
        "codigoGrade": "0499",
        "descricaoGrade": "KIRAZ",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0504",
        "descricaoGrade": "NURAY",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0500",
        "descricaoGrade": "EBRU",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0479",
        "descricaoGrade": "SARILA",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0191",
        "descricaoGrade": "GIZEM",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0195",
        "descricaoGrade": "SEVDA",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0194",
        "descricaoGrade": "UMAY",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0190",
        "descricaoGrade": "AYLA",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0502",
        "descricaoGrade": "DILARA",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0316",
        "descricaoGrade": "NUBIA",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0323",
        "descricaoGrade": "MANARA",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0436",
        "descricaoGrade": "NERINA",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0493",
        "descricaoGrade": "URBANO",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0497",
        "descricaoGrade": "UTOPIA",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0003",
        "descricaoGrade": "COMFORT",
        "possuiFichaTecnica": false
      },
      {
        "codigoGrade": "0503",
        "descricaoGrade": "MARRAKESH",
        "possuiFichaTecnica": false
      }
    ])
  }

}
