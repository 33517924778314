<div class="video-container">
  <video
    width="100%"
    height="100%"
    loop
    muted
    autoplay
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true"
  >
    <source
      type="video/mp4"
      src="https://minori-casa.s3.amazonaws.com/site-images/home/video-site-minori.mp4"
    />
  </video>
  <div class="overlay"></div>
</div>
