import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginInstitucional } from '../login-institucional/login-institucional.service';
import { ObterIdentificador, ObterIdentificadorService } from '../sign-in/obter-identificador.service';
import { environment } from 'src/environments/environment';






export interface SignIn {
  fantasia?: string;
  codTabelaPreco?: string;
  usuario?: string;
  id?: number;
  cnpj?: string;
  email?: string;
  codCliente?: string;
  perfil?: {
    id: number,
    perfil: string
  };
  token: string;
}

export interface Login {
  email: string;
  senha: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnInit {
  
  private relativeLink = 'public/v1/drctLogin'; 
   
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(localStorage.getItem('identificador') != null ? true : false);
  private info$: BehaviorSubject<object> = new BehaviorSubject<object>({});
  private identificadorMarca$: BehaviorSubject<ObterIdentificador> = new BehaviorSubject<ObterIdentificador>(null);
  private codTabela$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private login$: BehaviorSubject<LoginInstitucional> = new BehaviorSubject<LoginInstitucional>(null);
  public emitRugCare: EventEmitter<any> = new EventEmitter<any>();
  public emitLogin: EventEmitter<boolean> = new EventEmitter<boolean>(false);


  constructor(
    private http: HttpClient,
    private router: Router,
    private obterIdentificadorService: ObterIdentificadorService
  ) { }

  ngOnInit(): void {
    this.isActive();
    this.identificadorUrl();
  }



  get information$(): Observable<SignIn> {
    const info = JSON.parse(localStorage.getItem('information'));
    this.info$.next(info);
    return this.info$ as Observable<SignIn>;
  }


  get isLogged$() {
    const identificador: SignIn = JSON.parse(localStorage.getItem('information'));
    if (!identificador) {
      this.isLoggedIn$.next(false);
    } else {
      this.isLoggedIn$.next(true);
    }
    return this.isLoggedIn$.asObservable();
  }

  get identificador$() {
    const identificador: ObterIdentificador = JSON.parse(localStorage.getItem('identificador'));
    this.identificadorMarca$.next(identificador);
    return this.identificadorMarca$.asObservable();
  }

  login(user: Login): Observable<any> {
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, user) as Observable<any>;
    // .pipe(
    //   map((item: SignIn) => {
    //     console.log(item, 'item');
    //     if (item) {
    //       localStorage.setItem('information', JSON.stringify(item));
    //       this.isLoggedIn$.next(true);
    //     }
    //   })
    // );
  }

  /**S
   * FUNÇÃO QUE EFETUA O LOGOUT
   * ELA LIMPA A SESSION E NAVEGA PARA O LOGIN
   */
  logout() {
    localStorage.removeItem('information');
    localStorage.removeItem('codTabelaPreco');
    this.isLoggedIn$.next(false);
    // location.href = location.href.split('?')[0] + `?ts=${this.utilService.getTimeStamp()}`;
    this.router.navigate(['/']);
  }
  
  get codTabelaPreco$() {
    const codTabelaPreco: string = localStorage.getItem('codTabelaPreco') ?? '003';
    this.codTabela$.next(codTabelaPreco);
    return this.codTabela$.asObservable();
  }

  get loginInstitucional$() {
    const loginInstitucional: LoginInstitucional = JSON.parse(localStorage.getItem('loginInstitucional'));
    this.login$.next(loginInstitucional);
    return this.login$.asObservable();
  }

  public setLoginInstitucional(dados: LoginInstitucional) {
    localStorage.setItem('loginInstitucional', JSON.stringify(dados));
  }

  isActive() {
    let codTabelaPreco = ' ' as string;
    this.codTabelaPreco$.subscribe(data => codTabelaPreco = data);

    if(!codTabelaPreco){
      this.logout();
      // location.href = location.href.split('?')[0] + `?ts=${this.utilService.getTimeStamp()}`;
    }

      this.isLogged$
        .subscribe((data) => {
          if (
            data === true && codTabelaPreco ||
            this.router.url === '/forgot-password'
          ) {
          } 
          // else if (data === true && !codTabelaPreco) {
          //   this.router.navigate(['login']);
          //   location.href = location.href.split('?')[0] + `?ts=${this.utilService.getTimeStamp()}`;
          // } 
        //   else {
        //     this.router.navigate(['login']);
        //   }
        });
    // this.isLogged$.subscribe((data) => {
    //   if (data === true) {
    //   } else {
    //     this.router.navigate(['/']);
    //   }
    // });
  }

  identificadorUrl() {
    this.obterIdentificadorService
      .obterIdentificador()
      .subscribe((data) => {
        localStorage.setItem('identificador', JSON.stringify(data));
      },
        (error: HttpErrorResponse) => {
          console.log('Error ao obter o identificador.', error.error.mensagem);
        });
  }

}
