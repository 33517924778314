import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface FaleConoscoEnvio {
  url: string;
  nome: string;
  ddd: string;
  telefone: string;
  email: string;
  mensagem: string;
  assunto: string;
  idTipoCliente: number;
  destinatarios: Array<FaleConoscoEnvioEmail>;
}

export interface FaleConoscoEnvioEmail {
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class FaleConoscoService {

  private relativeLink = 'public/v1/newsletterInstitucional';

  constructor(private http: HttpClient) { }

  public enviarEmailFaleConosco(obj: FaleConoscoEnvio) {
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, obj);
  }
}
