import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JsonGenerico } from 'src/app/interfaces/json-generico.model';

export interface HomeSessoes {
  menus: Array<ItemMenu>;
  sessoes: Array<HomeSessoesItem>;
}

export interface HomeSessoesItem {
  sessao: string;
  produtos: Array<any>;
  ordem: number;
  id: number;
}

export interface HomeBanners {
  ordem: number;
  id: number;
  vigenciaDe: string;
  url: string;
  urlDirecionada: string;
  descricao: string;
  vigenciaAte: string;
}

export interface ItemMenu {
  codigo: string;
  descricaoMenu: string;
  subTitulo: string;
  ordem: number;
  publicado: number;
  descricao: string;
  subMenu: Array<SubMenu>;
  exibeAgrupamentoCores?: number;
}

export interface SubMenu {
  codigo: string;
  descricao: string;
  breadcrumb?: string;
  exibeAgrupamentoCores?: number;
  ordem: number;
  route?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private relativeLink = 'public/v1/jsonGenerico';

  constructor(private http: HttpClient) { }

  public homeSessoes(codTabelaPreco?: string): Observable<JsonGenerico<HomeSessoes>> {
    const body = {
      servico: 'drctHomeSessoes',
      codTabelaPreco
    };
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, body) as Observable<JsonGenerico<HomeSessoes>>;
  }

  public homeBanners(): Observable<JsonGenerico<Array<HomeBanners>>> {
    const body = {
      servico: 'drctHomeBanners'
    };
    return this.http.post(`${environment.url.api}/${this.relativeLink}`, body) as Observable<JsonGenerico<Array<HomeBanners>>>;
  }

}
