import { Component, OnInit, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SobreNosDivQuadrado } from 'src/app/interfaces/sobre-nos.model';
import { FaleConoscoService, FaleConoscoEnvio } from 'src/app/services/fale-conosco/fale-conosco.service';
import { SessionService } from 'src/app/services/session/session.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { HttpErrorResponse } from '@angular/common/http';
import { ObterIdentificador } from 'src/app/services/sign-in/obter-identificador.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export class Contact {

  constructor(
    public nome: string,
    public email: string,
    public ddd: string,
    public telefone: string,
    public mensagem: string,
    public companyName: string
  ) { }

}

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit, AfterViewInit, OnChanges {
    public loading = false;

    private identificador: ObterIdentificador;

    public telefoneMarca = "(11) 2423 3339";
    public isMobile = false;
  
    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private sessionService: SessionService,
      private cdr: ChangeDetectorRef
    ) {
      this.isMobile = screen.width > 768 ? false : true;
    }
  
    ngOnInit(): void {
      this.changeRoute();
    }
  
    ngOnChanges(changes: SimpleChanges): void {
      this.changeRoute();
      this.sessionService.identificador$.subscribe(
        (data) => (this.identificador = data)
      );
    }
  
    ngAfterViewInit() {
      // this.defineBanners();
      // this.isMobile = screen.width > 768 ? false : true;
      // this.cdr.detectChanges();
    }
  
    ngAfterViewChecked() {
      // this.defineBanners();
      // this.isMobile = screen.width > 768 ? false : true;
      // this.cdr.detectChanges();
    }
  
    public goTo(url: SobreNosDivQuadrado) {
      if (url.tipoLink === "href") {
        window.open(url.link, "blank");
      } else {
        this.router.navigate([url.link]);
      }
    }
  
    private changeRoute() {
      this.route.fragment.subscribe((data) => {
        if (data != null) {
          setTimeout(() => {
            const div = innerWidth > 959 ? "divConosco" : "divConoscoMob";
            console.log(div, innerWidth);
  
            document.getElementById(div).scrollIntoView();
          }, 1000);
        }
      });
    }
  
    goToFixedLink(link) {
      return this.router.navigate([link]);
    }
  
  }
  