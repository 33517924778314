import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CarrinhoRetorno, CarrinhoRetornoItens, CartService, ResumoCarrinho } from 'src/app/services/cart/cart.service';
import { SessionService } from 'src/app/services/session/session.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-modal-my-carts',
  templateUrl: './modal-my-carts.component.html',
  styleUrls: ['./modal-my-carts.component.scss']
})
export class ModalMyCartsComponent implements OnInit {
    public linksLogo = {
        positivo: '../../assets/images/logo/logo-branco.svg',
        negativo: 'https://www.minoricasa.com/assets/images/logo/logo-bege-escuro.png',
        preto: '../../assets/images/logo/logo-preto.svg',
        mobile: 'https://maioricasa.s3.amazonaws.com/logos/m-maioricasa-marrom.svg'
      };
    
      public itensReduzidos: number = 0;
    
      total = [];
      grandTotal = 0;
      cartItemCount = [];
      cartItemCountTotal = 0;
    
      codCliente: string;
      carrinho: CarrinhoRetorno;
      private itensOriginal: Array<CarrinhoRetornoItens> = [];
      codTabelaPreco: string;
      resumoCarrinho: ResumoCarrinho;
      loading: boolean = false;
      @ViewChild(MatAccordion) accordion: MatAccordion;
    
      public statusCarrinho: boolean = true;
    
      public produtos;
      public productItems;
    
      constructor(
        public appService: AppService,
        private cartService: CartService,
        private sessionService: SessionService,
        private snackBar: MatSnackBar,
        private router: Router,
        public location: Location,
        public dialogRef: MatDialogRef<ModalMyCartsComponent>,
        private utilService: UtilService,
        @Inject(MAT_DIALOG_DATA) public data: any
      ) {}
    
      ngOnInit() {
        this.sessionService.information$.subscribe(
          (data) => {
            this.codCliente = data.codCliente;
            this.codTabelaPreco = data.codTabelaPreco;
          },
          (error) => {
            console.log("Error on sesssionService");
          }
        );
    
        this.produtos = this.data;
        console.log(this.produtos);
      }
    
      public backToProductsRoute() {
        this.loading = true;
        this.location.back();
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    
      public formatterValue(valor) {
        let val = (valor / 1).toFixed(2).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    
      public checkoutRoute() {
        this.dialogRef.close();
        this.router.navigate(["/checkout"]);
      }
    
      recuperar() {
        this.loading = true;
        this.cartService
          .recuperarCarrinho(this.produtos.idCarrinho, this.codCliente)
          .subscribe(
            (data) => {
              this.loading = false;
              this.snackBar.open(
                `Cart ${this.produtos.idCarrinho} successfully recovered!`,
                "",
                {
                  panelClass: "success",
                  verticalPosition: "bottom",
                  duration: 2000,
                }
              );
              this.fecharModal(true);
              // this.carrinhosAbandonados(this.codCliente);
            },
            (error: HttpErrorResponse) => {
              this.loading = false;
              this.snackBar.open(
                `Error trying to retrieve cart ${this.produtos.idCarrinho}.`,
                "",
                {
                  panelClass: "warn",
                  verticalPosition: "bottom",
                  duration: 2000,
                }
              );
              console.log(`Error: ${error.error.mensagem}`);
            }
          );
      }
    
      fecharModal(atualizarItens: boolean) {
        this.dialogRef.close(atualizarItens);
      }
    }
    