import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SwiperModule } from "ngx-swiper-wrapper";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
// import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
    suppressScrollX: true,
};

import { PipesModule } from "../theme/pipes/pipes.module";
import { RatingComponent } from "./rating/rating.component";
import { ControlsComponent } from "./controls/controls.component";
import { MainCarouselComponent } from "./main-carousel/main-carousel.component";
import { BrandsCarouselComponent } from "./brands-carousel/brands-carousel.component";
import { ProductsCarouselComponent } from "./products-carousel/products-carousel.component";
import { ProductDialogComponent } from "./products-carousel/product-dialog/product-dialog.component";
import { CategoryListComponent } from "./category-list/category-list.component";
import { LoadingComponent } from "./loading/loading.component";
import { DepoimentosComponent } from "./depoimentos/depoimentos.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { MidiaCarouselComponent } from "./midia-carousel/midia-carousel.component";
import { BannersComponent } from "./banners/banners.component";
import { ModalContactComponent } from "./modal-contact/modal-contact.component";

export let options: Partial<IConfig> | (() => Partial<IConfig>);

// Add these two
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { ModalSignInComponent } from "./modal-sign-in/modal-sign-in.component";
import { ModalMyCartsComponent } from "./modal-my-carts/modal-my-carts.component";
import { ModalCartComponent } from "./modal-cart/modal-cart.component";
import { ModalSobMedidaComponent } from "./modal-sob-medida/modal-sob-medida.component";
import { VideoSectionComponent } from "./video-section/video-section.component";

// Export this function
export function playerFactory(): any {
    return import("lottie-web");
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SwiperModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        // MatStepperModule,
        PerfectScrollbarModule,
        PipesModule,
        ReactiveFormsModule,
        FormsModule,
        NgxMaskModule.forRoot(options),
        LottieModule.forRoot({ player: playerFactory }),
    ],
    exports: [
        RouterModule,
        SwiperModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        // MatStepperModule,
        PerfectScrollbarModule,
        PipesModule,
        RatingComponent,
        ControlsComponent,
        MainCarouselComponent,
        BrandsCarouselComponent,
        ProductsCarouselComponent,
        ProductDialogComponent,
        BannersComponent,
        CategoryListComponent,
        LoadingComponent,
        DepoimentosComponent,
        MidiaCarouselComponent,
        ModalContactComponent,
        VideoSectionComponent,
    ],
    declarations: [
        RatingComponent,
        ControlsComponent,
        MainCarouselComponent,
        BrandsCarouselComponent,
        ProductsCarouselComponent,
        ProductDialogComponent,
        BannersComponent,
        CategoryListComponent,
        LoadingComponent,
        DepoimentosComponent,
        MidiaCarouselComponent,
        ModalContactComponent,
        ModalSignInComponent,
        ModalMyCartsComponent,
        ModalCartComponent,
        ModalSobMedidaComponent,
        VideoSectionComponent,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class SharedModule {}
