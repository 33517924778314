<div fxLayout="row" *ngIf="produtos?.itens.length > 0">
    <div fxFlex="65">
        <div class="p-0 main" fxShow="false" fxShow.gt-sm>
            <div class="aligner">
                <h3 class="title">Products</h3>
                
                <hr class="line" />
                
                <div class="mat-table cart-table">
                    <div *ngFor="let produto of produtos?.itens; let index = index" class="mat-row">
                        <!-- img do produto -->
                        <div fxFlex="17" class="mat-cell"><img [src]="produto.url"></div>
                        
                        <!-- descrição do produto -->
                        <div fxFlex="40"> 
                            <div class="mat-cell text-right descricao">
                                <h3>{{produto?.descricao}}</h3>
                            </div>
                            <!-- descrição do tamanho do SOB MEDIDA -->
                            <!-- *ngIf="produto?.vendaSobMedidaM2 == 1" -->

                            <!-- <div class="mat-cell text-left text-muted descricao" *ngIf="produto.comprimento > 0 && produto.largura > 0">
                                <span> 
                                    <p> <strong>Comp.:</strong> {{produto?.comprimento}}m | <strong>Larg.:</strong> {{produto.largura}}m</p>
                                </span>
                            </div> -->

                            <!-- info dos produtos SOB MEDIDA -->
                            <!-- *ngIf="produto.vendaSobMedidaM2 == 1" -->
                            <!-- <div class="mat-cell text-right descricao" *ngIf="produto.comprimento > 0 && produto.largura > 0">
                                <p> <strong>Preço Medida:</strong> {{produto.totaIBruto | currency }}</p>
                            </div> -->

                            <!-- *ngIf="produto.lotes.length == 0 && produto.vendaSobMedidaM2 == 0"  -->
                            <div class="mat-cell text-right  descricao">
                                <p><strong>Unit Price:</strong> {{produto.preco | currency }}</p>
                            </div>
                            <div class="mat-cell text-right  descricao">
                                <p><strong>Subtotal:</strong> {{produto.totaIBruto | currency }}</p>
                            </div>
                            <div class="mat-cell text-right  descricao">
                                <p><strong>Total Item:</strong>  {{produto.totaITem | currency }}</p> 
                            </div>
                        </div>

                        <!-- quantidade SOB MEDIDA -->
                        <!-- *ngIf="produto.vendaSobMedidaM2 == 1" -->
                        <!-- <div *ngIf="produto.comprimento > 0 && produto.largura > 0 || produto.quantidade > 0" fxFlex="30" style="text-align: center;" class="mat-cell text-muted">{{produto.quantidade}}</div> -->
                    </div>
                </div>
                <hr class="line" />
            </div>
        </div>
        <div class="botoes">
            <div fxFlex="60">
                <a mat-button class="flex-row-button btn" (click)="fecharModal(false)">
                    <mat-icon class="mat-icon-md">navigate_before</mat-icon>
                    <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs
                        class="top-cart">
                        <span style="font-size: 16px">Close</span>
                    </div>
                </a>
            </div>
            <!-- <div fxFlex="40">
                <a mat-button class="flex-row-button btn" (click)="excluirCarrinho()" style="margin: 1.25rem 3.625rem">
                    <div class="lixo" fxLayoutAlign="space-around center" >
                        <i style="font-size: 20px;" class="fas fa-trash-alt"></i>
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="end center" fxShow="false" fxShow.gt-xs
                        class="top-cart">
                        <span style="font-size: 16px">Limpar Carrinho</span>
                    </div>
                </a>
            </div> -->
        </div>
    </div>
    <div fxFlex="39" class="values" style="border-radius: 15px 0px 0px 15px;">

        <div fxLayout="column" fxLayoutAlign="start start" style="height: 95%">
            <div fxFlex>

            <div class="aligner">
                <h3 class="title-bloco">Cart overview {{produtos?.idCarrinho}}</h3>
                <p>{{produtos?.totalItens}} items</p>             
            </div>
            <hr class="line-bloco" />
            </div>
            <div fxFlex="none">

              <div fxLayout="column" fxLayoutAlign="space-between" class="mat-row btnsCart">
                <div fxLayout="row" fxFlex="33" class="mat-cell">
                    <h3 class="title-bloco"> Total: </h3>
                    <span class="title-valor">{{ produtos?.totalValor | currency }}</span>
                </div>
                <div style="width: 17.2rem;" fxFlex="33" class="text-center mat-cell">
                    <button (click)="recuperar()" mat-raised-button color="primary" class="w-100 checkout">
                        Recovery
                    </button>
                </div>
            </div>
          </div>
    </div>
