<div *ngIf="logged" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
    <hr class="line mt-1" />
    <cdk-accordion class="example-accordion">
        <cdk-accordion-item #accordionItem="cdkAccordionItem">
            <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                <div fxLayout="row wrap" (click)="toggleContent()">
                    <div class="account">
                        <img src="{{ user.logged }}" alt="account-user">
                    </div>
                    <div style="font-size: 1.25rem;" class="user_information">
                        <p>{{userInformation?.fantasia}} <br>
                            <small style="font-size: 12px;">{{userInformation?.email}}</small>
                        </p>   
                    </div>
                    <div class="toggle-btn theme-container example-accordion-item-description">
                        <i style="font-size: 20px;" class="fas fa-chevron-up icon" [ngClass]="{ 'rotate': isOpen }"></i>
                    </div>
                </div>
            </div>
            <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'">
                <span>
                    <div class="divider itens">
                        <a mat-menu-item routerLink="/account" class="icons">
                            <img class="cart" src="https://maioricasa.s3.amazonaws.com/site-images/icons/icon-settings.png">
                            <span>Account Information</span>
                        </a>
                        <a mat-menu-item fxHide="false" routerLink="/account/orders" class="icons">
                            <img class="cart" src="https://maioricasa.s3.amazonaws.com/site-images/icons/icon-orders.png">
                            <span>My orders</span>
                        </a>
                        <a mat-menu-item fxHide="false" routerLink="/account/my-carts" class="icons">
                            <img class="cart" src="https://minori-casa.s3.amazonaws.com/site-images/icons/cart.png">
                            <span>My carts</span>
                        </a>
                    </div>
                    <div class="divider">
                        <button mat-menu-item (click)="logout()" class="icons">
                            <img class="cart" src="https://maioricasa.s3.amazonaws.com/site-images/icons/icon-exit.png">
                            <span style="margin-left: 10%;">Sign Out</span>
                        </button>
                    </div>
                </span>
            </div>
        </cdk-accordion-item>
    </cdk-accordion>
    <hr class="line" />
</div>
<div *ngFor="let m of topMenu" class="menu-item">
    
    <a mat-button *ngIf="m.subMenu.length == 0 && !m.fragment && !m.function" fxLayout="row" fxLayoutAlign="start center" (click)="goTo(m)" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}" [id]="'menu-item-'+ m.id">
        <span class="menu-title">{{m.label}}</span>
    </a>

    <!-- <a mat-button *ngIf="m.subMenu.length == 0 && m.fragment && !m.function && isLogged" fxLayout="row" fxLayoutAlign="start center" (click)="goTo(m)" [fragment]="m.fragmentString" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}" [id]="'menu-item-'+ m.id">
        <span class="menu-title">{{m.label}}</span>
    </a> -->

    <a mat-button *ngIf="m.subMenu.length == 0 && m.function" fxLayout="row" fxLayoutAlign="start center" (click)="goTo(m)" [fragment]="m.fragmentString" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}" [id]="'menu-item-'+ m.id">
        <span class="menu-title">{{m.label}}</span>
    </a>

    <a mat-button *ngIf="m.subMenu.length == 0 && m.selected === false" fxLayout="row" fxLayoutAlign="start center" (click)="goTo(m)" [fragment]="m.fragmentString" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}" [id]="'menu-item-'+ m.id">
        <span class="menu-title">{{m.label}}</span>
    </a>

    <a *ngIf="m.subMenu.length > 0" mat-button fxLayout="row" fxLayoutAlign="start center" (click)="onClick(m.id, topMenu)" [id]="'menu-item-'+m.id">
        <span *ngIf="m.subMenu.length > 0" class="menu-title">{{m.label}}</span>
        <mat-icon class="menu-expand-icon transition-2">keyboard_arrow_down</mat-icon>
    </a>


    <div *ngIf="m.subMenu.length > 0" class="sub-menu" [id]="'sub-menu-'+ m.id">
        <div *ngFor="let s of m.subMenu">
            <a *ngIf="s.linkInterno" mat-button class="menu-item" href="{{s.route}}">
                <span class="menu-title">{{s.descricao}}</span>
            </a>

            <a *ngIf="!s.linkInterno" mat-button class="menu-item" href="{{s.route}}" target="_blank">
                <span class="menu-title">{{s.descricao}}</span>
            </a>
        </div>
    </div>

</div> 