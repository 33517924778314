import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface ListaPaises {
  codPais: string;
  codPais1: string;
  id: number;
  nome: string;
  ordem: number;
  ordemUS: number;
}

@Injectable({
  providedIn: 'root'
})
export class ListaPaisesService {

  constructor(
    private http: HttpClient
  ) { }

  listaPaises() {
    return this.http.get(`${environment.url.api}/public/v1/directPaises`) as Observable<any>
  }
}
