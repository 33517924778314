import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SignInService } from '../../../services/sign-in/sign-in.service';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<File>();
  files: any = [];
  resaleCertificate: File;
  resalePreview: string;

  SERVER_URL = 'http://web.minoricasa.com:5002/upload';
  uploadForm: FormGroup;
  haveFile = false;
  public loading = false;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private signInService: SignInService
  ) { }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  uploadFile(file) {
    if (file.length > 1) {
      this.snackBar.open(`Unable to upload. Only one file in PDF extension are supported.`, '', {
        panelClass: 'warning', verticalPosition: 'bottom', duration: 5000
      });
    } else if (file.length <= 1) {
      // if (file[0].type === 'application/pdf') {
        this.resaleCertificate = file[0];
        this.uploadForm.get('profile').setValue(this.resaleCertificate);
        this.addNewItem();
        const reader = new FileReader();
        reader.onload = (event: any) => this.resalePreview = event.target.result;
        reader.readAsDataURL(file[0]);
      // } else {
      //   this.snackBar.open(`Could not upload ${file[0].name}. Only files with PDF extension are supported.`, '', {
      //     panelClass: 'warning', verticalPosition: 'bottom', duration: 5000
      //   });
      // }
    } else {
      // if (file.type === 'application/pdf') {
        this.resaleCertificate = file;
        this.uploadForm.get('profile').setValue(this.resaleCertificate);
        this.addNewItem();
        const reader = new FileReader();
        reader.onload = (event: any) => this.resalePreview = event.target.result;
        reader.readAsDataURL(file);
      // } else {
      //   this.snackBar.open(`Could not upload ${file.name}. Only files with PDF extension are supported.`, '', {
      //     panelClass: 'warning', verticalPosition: 'bottom', duration: 5000
      //   });
      // }
    }

  }
  deleteAttachment(index?) {
    this.resaleCertificate = null;
    this.haveFile = false;
    this.addNewItem(true);
  }


  addNewItem(del?: boolean) {
    if (!del) {
      this.loading = !this.loading;
      this.signInService.uploadResaleCertificate(this.resaleCertificate)
        .subscribe((data) => {
          this.haveFile = true;
          this.newItemEvent.emit(data.url);
          this.loading = !this.loading;
          // this.snackBar.open(`${data.file}`, '', {
          //   panelClass: 'sucess', verticalPosition: 'bottom', duration: 5000
          // });
        }, (err) => {
          this.deleteAttachment();
          this.loading = !this.loading;
          this.snackBar.open(`Could not upload.Try again.`, '', {
            panelClass: 'error', verticalPosition: 'bottom', duration: 5000
          });
        });
    }
  }

}
